#search-page-heading
  background: white
  padding: 15px
  margin-top: 40px

.search-page-result-box
  background: white
  padding: 23px
  margin-top: 20px
  margin-bottom: 20px
  
  a 
    overflow-wrap: break-word
    
  img
    display: none

  .search-thumbnail
    display: block
    width: 100%
