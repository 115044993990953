.latest
  +clearfix
  h3.line
    margin-top: $margin*2
  .row.row-eq-height
    .item
      margin-bottom: $margin*2

      a
        color: $default
        text-decoration: none

      .card
        border-radius: 0
        border: none
        height: 100%

      img
        height: 100%
        width: 100%

      .card-footer
        padding: 0 0 .75rem 0
        background-color: transparent
        border-top: 0
        margin: $margin 0
        color: $grey

        .date
          float: left

        .comments
          float: right
          @include media-breakpoint-down(md)
            float: left
            margin-right: 1rem


      .badge
        position: absolute
        top: 16px
        right: 0
        padding: 1rem
        border-radius: 0
        z-index: 1

        &.badge-webinars
          background-color: $green
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-seminars
          background-color: $trafficlightred
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-ontour
          background-color: $black
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-other
          background-color: $grey
          text-transform: uppercase
          font-weight: 500
          color: $white

        &.badge-tag
          background-color: $default
          text-transform: uppercase
          font-weight: 500
          color: $white
          top: 49px

  .grid-item
    float: left
    margin-bottom: $margin
    width: calc((100% - 40px) / 3)
    @media (max-width: 768px)
      width: calc((100% - 40px) / 2)
    @media (max-width: 600px)
      width: calc((100% - 20px) / 2)

    a
      color: $default
      text-decoration: none

    .card
      border-radius: 0
      position: relative
      border: none

    img
      height: 100%
      width: 100%

    .card-footer
      padding: 0
      background-color: transparent
      border-top: 0
      margin: $margin 0
      color: $grey

      .date
        float: left

      .comments
        float: right
        @include media-breakpoint-down(md)
          float: left
          margin-right: 1rem


    .badge
      position: absolute
      top: 16px
      right: 0
      padding: 1rem
      border-radius: 0
      z-index: 1

      &.badge-webinars
        background-color: $green
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-seminars
        background-color: $trafficlightred
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-ontour
        background-color: $black
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-other
        background-color: $grey
        text-transform: uppercase
        font-weight: 500
        color: $white

      &.badge-tag
        background-color: $default
        text-transform: uppercase
        font-weight: 500
        color: $white
        top: 49px