.icon-list
  .icon-container
    text-align: center
    +font-size(3)
    display: flex
    align-items: center
    justify-content: center
  &.custom-icon-list
    align-content: center
    min-height: 44px
    p
      margin-bottom: 0
