// Mixins

=font-size($sizeValue: 1.7)
  font-size: ($sizeValue * 10) + px
  font-size: $sizeValue + rem

=transition($duration: .3s)
  -webkit-transition: all ease $duration
  -moz-transition: all ease $duration
  -ms-transition: all ease $duration
  -o-transition: all ease $duration
  transition: all ease $duration

=clearfix
  &:after
    content: ""
    display: table
    clear: both
