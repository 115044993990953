.category-hero-left, .category-hero-right
  +clearfix

  .row
    margin-bottom: $margin / 1.5
    @include media-breakpoint-down(md)
      margin-bottom: 0

    a
      color: $default
      text-decoration: none

      .card
        border-radius: 0
        border: none
        height: 100%
        position: relative

        .video-head
          position: relative

        img
          height: auto
          width: 100%

        .card-footer
          padding: 0
          background-color: transparent
          border-top: 0
          color: $grey

          .date
            float: left
            @include media-breakpoint-down(md)
              float: none
            @include media-breakpoint-down(sm)
              float: left

          .comments
            float: right
            @include media-breakpoint-down(md)
              float: none
            @include media-breakpoint-down(sm)
              float: right

        &.hero
          height: calc(100% - 1.2rem)
          @include media-breakpoint-down(md)
            height: auto

          img
            height: 662px
            @include media-breakpoint-down(lg)
              height: 595px
            @include media-breakpoint-down(md)
              height: 510px
            @include media-breakpoint-down(sm)
              height: auto

