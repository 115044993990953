.multicol
  .col    
    @media (max-width: 991px)
      flex-basis: auto
      margin-bottom: 3rem
      max-width: 50%
      width: 50%
    @media (max-width: 767px)
      max-width: 100%
      width: 100%
  img
    width: 100%