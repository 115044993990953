dl
  margin-top: $margin
  margin-bottom: $margin
  dt, dd
    padding: $padding
    margin: 0 0 .2em
  dt
    background-color: rgba($default,.1)
  dd
    background-color: $white

    code
      color: $default

