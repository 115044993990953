//Navigation

ul.inline-nav
  margin: 0
  padding: 0
  li
    list-style: none
    margin: 0
    display: inline
    padding: $margin/2

body header
  .termine-uebersicht
    background-color: #888
    color: #fff
    &:hover
      color: unset

  .navbar-brand img
    height: 2.4rem
    width: auto
    @include media-breakpoint-down(xs)
      height: 1.8rem

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
      width: 100%

  #loginBox
    h2
      font-size: 2em

    form
      margin: $margin 0
      .form-control
        padding: 0.5em 1em
        margin-bottom: 1rem
        height: 40px
      .input-group-append
        height: 40px
        .input-group-text
          border: none
      a
        margin-left: 1rem
      .btn-secondary
        width: 50%
      .form-check
        margin-top: 1rem
    .register
      .btn-secondary
        width: 100%


  label[for="search"]
    margin: 0
  #searchBox
    background-color: $lightgreen
    color: rgba($white, .5)
    form
      .input-group-text
        background-color: transparent
        color: rgba($white, .5)
        border: 0
        font-size: 1.6em
        padding-left: 0

      input.form-control
        background-color: transparent
        &:active, &:focus
          box-shadow: none
        &::placeholder
          color: rgba($white, .5)
        color: white

  & > nav.navbar
    background-color: $white
    padding: $padding
    +font-size(1.4)
    ul.navbar-nav
      li
        a
          +transition()
        a.nav-link
          color: $default
          text-decoration: none
          padding: $margin * 0.5
          font-size: 1.6rem
          &:hover, &:active, &:focus, &:active:focus
            color: $mediumgrey
          &.dropdown-toggle
            &:after
              width: 1.5em
              height: 1em
              margin: 0
              line-height: 1
              vertical-align: -0.1em
              border: 0
              background:
                image: url("../../img/angle-down.svg")
                position: center
                repeat: no-repeat
        .dropdown-menu
          font-size: 1em
          &.show
            border: none
            border-radius: 0
            padding: 0
            margin: 0
          a.dropdown-item
            text-decoration: none
            padding: $padding
            &.hover, &:hover
              background-color: lighten($grey, 30%)
            &.active, &:active
              background-color: $green
            &.active:hover
              background-color: lighten($lightgreen, 10%)
          .dropdown-divider
            display: none

    .navbar-toggler
      border: none
      padding: 1.6rem

    .navbar-collapse.collapse
      margin-right: 16px
      @include media-breakpoint-up(lg)
        order: 1

    .container-fluid
      @include media-breakpoint-down(sm)
        justify-content: flex-end

    .navbar-wrapper
      @include media-breakpoint-up(lg)
        order: 2

  .bg-light
    background-color: $white!important

.navbar-wrapper.language-switch  
  margin-left: 10px
  @include media-breakpoint-down(sm)
    margin-left: unset