.featured

  .row
    margin-bottom: $margin / 1.5

    .col-md-6:first-of-type
      @include media-breakpoint-down(sm)
        margin-bottom: 3rem
  
    &.mb-5
      margin-bottom: 1rem !important

  h3
    margin-bottom: 1.6rem

  .news
    @include media-breakpoint-down(sm)
      margin-bottom: 3rem

    a
      color: $default
      text-decoration: none

    .card
      border-radius: 0
      border: none
      height: 100%
      position: relative
      display: inline-block

      h4
        margin-bottom: 3rem

      img
        height: auto
        width: 100%
        background-color: $mediumgrey

      .card-footer
        padding: 0
        background-color: transparent
        border-top: 0
        color: $mediumgrey
        position: absolute
        bottom: 1.25rem

      &.bgimg
        color: $white
        position: relative
        background:
          image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5)), url('../../../img/platzhalter.png')
          size: cover

        h4
          margin: 0 3rem 3rem 0
          position: absolute
          bottom: 1.6rem
          @include media-breakpoint-down(md)
            position: inherit

        .text-muted
          color: $white !important


  .events
    background-color: $default
    padding: 3rem
    margin-bottom: $margin
    @include media-breakpoint-down(md)
      padding: 1.6rem
      min-height: 0
    
    @include media-breakpoint-down(sm)
      margin-bottom: 1rem

    a
      text-decoration: none
      border-bottom: 1px solid $mediumgrey
      display: block
      &:last-of-type
        border-bottom: none
        margin-bottom: .5rem

      .card
        background-color: $default
        color: $white
        border-radius: 0
        border: none

        h4
          margin-bottom: 0

        .card-body
          padding: 2rem 0
          @include media-breakpoint-down(md)
            padding: 1rem 0

        .date-info
          overflow: hidden

      span
        padding-right: 2rem
        +font-size(5)
        float: left
        position: relative
        margin-top: -0.3rem
        @include media-breakpoint-down(md)
          display: none

      .text-muted
        color: $white!important

      small
        font-size: 1.7rem

