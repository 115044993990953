.event-detail-intro
  margin-top: 3rem
  +clearfix

  .topics-goals
    float: left

  ul
    p
      color: $default
      margin-bottom: 0

  .events
    background-color: $default
    padding: 3rem
    margin-bottom: $margin
    color: $white

    .event-anchor-link
      background: none
      color: inherit
      border: none
      cursor: pointer
      font: inherit
      outline: inherit
      text-decoration: underline

    hr
      border-bottom: 1px solid $mediumgrey
      &:last-of-type
        display: none

    h3
      &:not(:first-child)
        margin-top: $margin

    .card
      background-color: $default
      color: $white
      border-radius: 0
      border: none
      display: flex

      h4
        margin-bottom: 0

      .card-body
        padding: 2rem 0

      .date-info
        overflow: hidden

      img
        margin-right: 1rem
        float: left
        position: relative
        border-radius: 50%
        filter: grayscale(100%)
        width: 70px
        height: 70px
        object-fit: cover

      .text-muted
        color: $white!important

      small
        font-size: 1.7rem

      a
        float: right
        color: $white
