.hint

  .tipbox
    background-color: $green
    padding: $padding
    color: $white
    margin: 0
    display: flex
    align-items: center

  strong
    overflow: hidden
    vertical-align: middle
    position: relative
    top: 1px

  span
    padding-right: 1rem
    float: left
    font-size: 2rem
