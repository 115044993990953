.team-container
  margin-bottom: 2rem
  .card-block
    padding: 10px
    min-height: 160px

  .grid-item
    img.product-logo 
      height: 20px  
    float: left
    padding: 0
    background: #ffffff
    margin-bottom: 20px
    width: calc((100% - 50px) / 3)
    @media (max-width: 768px)
      width: calc((100% - 40px) / 2)
    @media (max-width: 600px)
      width: calc((100% - 20px))  

    .card-block
      p.card-text-fixed-height  
        height: 12rem
        overflow: hidden

      a.team-member-email
        text-decoration: none  

    .text-more-info
      position: absolute
      right: 10px
      bottom: 10px