body footer
  div.main-footer-bottom
    ul.inline-nav
      @include media-breakpoint-down(sm)
        text-align: center 
        li 
          display: inline !important
  .pre-footer
    @include media-breakpoint-down(sm)
      .col-md-4, .col-md-3
        padding-bottom: 35px
    .pre-footer-image 
      height: 3rem
      filter: brightness(0) invert(1)
    background-color: #3f3f3f
    padding: $padding
    padding-top: 4rem
    padding-bottom: 4rem 
    a
      color: rgba($white,.75)
      font-weight: 500
      text-decoration: none
      +transition()
      &:hover
        text-decoration: none
        color: rgba($white,1)
  .pre-footer-logo-container
    padding-bottom: 4rem 
  .main-footer
    padding: $padding
    padding-top: 4rem
    padding-bottom: 4rem
    nav.more-brands
      ul.inline-nav li
        padding: 0.5rem 1rem
        display: inline-block
    @include media-breakpoint-down(sm)
      text-align: center
      ul.inline-nav
        @media (max-width: 575px) 
          text-align: left 
      nav.more-brands
        text-align: left  
  background-color: $default
  color: $white
  position: relative
  margin-bottom: 20px
  clear: both
  @include media-breakpoint-up(sm)
    height: auto

  .row
    position: relative
    .brand-text
      font-weight: 900
    img
      display: inline-block
      height: 1.4em
      vertical-align: top

  nav.more-brands

    position: relative
    padding: 1.6rem
    background: $red
    margin-top: 1em
    li
      @include media-breakpoint-down(sm)
        display: block
        width: 100%


  nav.footer-nav
    margin-top: 1em
    text-align: center
    a 
      font-size: 14px 
      color: $white
    @include media-breakpoint-up(md)
      margin-top: 0
      text-align: right
      ul.inline-nav
        li:last-of-type
          padding-right: 0

  nav.footer-nav, nav.more-brands
    ul li a
      color: rgba($white,.75)
      font-weight: 500
      text-decoration: none
      +transition()
      &:hover
        text-decoration: none
        color: rgba($white,1)
  .copy
    @include media-breakpoint-down(sm)
      text-align: center

  .social
    margin-top: 1rem
    .icons
      float: right
      text-align: right
      @include media-breakpoint-down(sm)
        float: none
        text-align: center !important
    a
      color: $white
      margin-right: $margin / 2
      text-decoration: none
      @include media-breakpoint-down(md) 
        margin: 0