.downloads
  +clearfix

  /* .wrapper
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    margin: 0 -0.5rem */

  .box
    background-color: $white
    width: 24%
    margin: 0.5rem
    float: left
    min-height: 106px
    @media (min-width: 992px) and (max-width: 1199px)
      width: 23.9%
    @media (max-width: 991px)
      min-height: 84px
      width: 48.5%
    @include media-breakpoint-down(md)
      min-height: 0
      width: 100%

  a
    color: $default
    padding: 2rem
    text-decoration: none
    display: inline-block

  span
    float: left
    margin-right: 1rem

  p
    margin-bottom: 0
    overflow: hidden
