.flip
  -webkit-perspective: 800px
  perspective: 800px
  position: relative

  &:hover .card
    -moz-transform: rotatey(-180deg)
    -webkit-transform: rotatey(-180deg)
    transform: rotatey(-180deg)

  .card
    height: 100%
    -webkit-transform-style: preserve-3d
    -webkit-transition: 0.5s
    transform-style: preserve-3d
    transition: 0.5s
    transition-delay: .25s

    .face
      -moz-backface-visibility: hidden
      -webkit-backface-visibility: hidden
      backface-visibility: hidden

    .front
      position: absolute
      width: 100%

    .back
      -moz-transform: rotatey(-180deg)
      -webkit-transform: rotatey(-180deg)
      transform: rotatey(-180deg)

    .inner
      position: relative
      margin: 0

      .abs
        position: absolute
        top: 0
        left: 0
        right: 0
        padding: 1em

#modelle-overview
  a:focus, a:hover
    text-decoration: none

// IE 10 fix / 11 workaround
// works well on IE 10 & 11 on Windows 7
// does not behave in IE 11 on Windows 10
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
  .flip .card .face
    transform-style: preserve-3d
    transition: 0.5s
    transition-delay: 0.25s

    &.back
      visibility: hidden

  .flip:hover .card
    transform: rotatey(0deg)

    .face.front
      transform: rotatey(-180deg)
      visibility: hidden

    .face.back
      transform: rotatey(0deg)
      visibility: visible

// IE 9 workaround (no anim)
@media all and (min-width: 0\0) and (min-resolution: .001dpcm)
  .flip .card .face.back
    visibility: hidden

  .flip:hover .card .face.front
    visibility: hidden

  .flip:hover .card .face.back
    visibility: visible