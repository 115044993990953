section.humans
  margin-top: 3rem
  .row > div
    margin-bottom: $margin*2
  .card
    height: 100%
    border: none
    border-radius: 0

  img
    object-fit: cover

  .more
    h2, h3, h4, h5
      +font-size(2)
