$white: white

// 50 Shades of ...
$default: #3F3F3F
$mediumgrey: #545454
$grey: #949494
$lightgrey: #F3F3F3
$darklightgrey: #cccccc
$mediumlightgrey: #dcdcdc

$lightgreen: #48c0b1
$green: #14AA96
$darkgreen: #0C6054
$red: #E00B26

$lightred: #E64155
$yellow: #FFC800
$darkyellow: #937300
$orange: #FF8C00
$lightblue: #00A5E1
$darkblue: #005AB4

$turquoisish: #d1ecf1
$darkturqoisish: #0c5460

$trafficlightgreen: #6FCF97
$trafficlightyellow: #F2C94C
$trafficlightred: #EB5757