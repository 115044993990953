table, .table
  max-width: 100%
  width: 100%
  th
    border: 2px solid $lightgrey
    border-bottom: 0
    background-color: lighten($grey, 30%)
    padding: .5rem .75rem
  td
    border: 2px solid $lightgrey
    background-color: $white
    padding: .5rem .75rem

// for redactor content

.btn-xls-export
  margin-left: 0.2rem

.redactor-content
  .expand
    height: 100%
    width: 100%
    i
      float: right
      background-color: $lightgreen
      padding: 1rem
      color: $white

    svg
      background-color: $lightgreen
      color: white
      height: 40px
      padding: 5px
      right: 0
      width: 40px

  .table-responsive.expandable
    position: relative
    table
      margin-bottom: unset

// for regular table    
section.table, div.table
  font-size: 1.33rem
  .expand
    height: 100%
    width: 100%
    i
      float: right
      background-color: $lightgreen
      padding: 1rem
      color: $white

    svg
      background-color: $lightgreen
      color: white
      height: 40px
      padding: 5px
      right: 0
      width: 40px

  .fa-expand-arrows
    cursor: pointer
    
  .table-responsive.expandable
    cursor: unset !important
    position: relative
    table
      cursor: unset !important
      margin-bottom: unset
    &:hover
      cursor: pointer
      .expand
        display: block

table.events-overview
  border-spacing: 0 10px
  border-collapse: separate


  .event-radio-td
    input
      opacity: 0
      position: absolute
      z-index: -1

    img
      display: inline-block
      padding: 0px
      height: 30px
      width: 30px
      background: none
      border: 1px solid #666666

    input:checked +img
      background: url(../../img/check_box.png)
      background-repeat: no-repeat
      background-position: center
      background-size: 30px 30px

  tr
    th
      background-color: lighten($grey, 30%)
      border-top: none      
      font-weight: 400
      line-height: 22px
      text-align: center
      vertical-align: middle
      @include media-breakpoint-up(sm)
        font-size: 12px
        padding: 10px 50px
      span
        display: block
        font-size: 36px
        font-weight: 700
        line-height: 26px
    td
      background-color: $white
      border-top: none
      vertical-align: middle
      @include media-breakpoint-up(sm)
        padding: 20px
      @include media-breakpoint-down(sm)
        font-size: 1rem
      strong
        display: block
      span.dot
        display: block
        text-align: left
        font-size: 40px
        font-weight: 700
        line-height: 20px
        &.green
          color: $trafficlightgreen
        &.yellow
          color: $trafficlightyellow
        &.red
          color: $trafficlightred
    select
      background: url(./img/angle-down.svg) no-repeat right center $white
      border: 0
      outline: 0
      padding-right: 15px
      -webkit-appearance: none

      &:hover
        cursor: pointer

table.subscriptions-options
  border-spacing: 0 10px
  border-collapse: separate
  width: 100%
  &.discount
    th
      background-color: $green
      color: $white
      text-indent: 8px
      padding: $padding / 2
    tr
      td
        border-top: 2px solid $green
        border-bottom: 2px solid $green
        &:first-of-type
          border-left: 2px solid $green
        &:last-of-type
          border-right: 2px solid $green
    .price
      span
        color: $green
        font-weight: 600
        text-decoration: line-through
        display: block
        @include media-breakpoint-down(md)
          font-size: 2rem
  tr
    td
      background-color: $white
      padding: $padding
      .form-check-label
        margin-bottom: $margin / 3
        padding-left: $padding * 1.4
    span
      display: block
    strong
      display: block
    .price
      span
        font-size: 3rem
        font-weight: 600
        @include media-breakpoint-down(md)
          font-size: 2rem

    select
      background: url(./img/angle-down.svg) no-repeat 90% center $white
      background-size: 12px
      border-radius: 0
      border: 1px solid $lightgrey
      outline: 0
      padding: .8rem 3rem .8rem .8rem
      -webkit-appearance: none

.modal.show
  &:hover
    cursor: pointer
  @include media-breakpoint-down(sm)
    pointer-events: none
  &.show
    display: flex !important
    flex-direction: column
    justify-content: center
    padding: 50px !important
    .closer
      svg
        color: white
        float: right
        height: 40px
        width: 40px
