#intro-section
  .owl-dots
    display: none
  .single-item
    height: 600px
    margin-bottom: 2rem
    background-size: cover
    background-position: center
    display: block
    width: 100%

