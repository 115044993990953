.image-text-overlay
  position: relative
  .copyright-text
    position: absolute
    display: block
    color: $white
    transform: rotate(-90deg) translate(100%, 0%)
    right: 1em
    transform-origin: right
    font-size: .6em
    padding: .25em
    bottom: 0
    @include media-breakpoint-down(md)
      bottom: 10rem
  &.col-testimonial-image .copyright-text
    @include media-breakpoint-down(md)
      bottom: 0rem

.figcaption
  font-size: 0.7em
