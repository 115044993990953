.content-support
  .hilfe-description-list
    .redactor-content
      line-height: 18px
      font-size: 14px
      background-color: white
      margin-bottom: -8px
      padding: 0

      p
        margin: 0
      