body > .alert
  margin-bottom: 0

.alert
  border-radius: 0
  border: none
  padding: 1rem
  margin-bottom: 0
  p:last-of-type
    margin-bottom: 0

  &.alert-success
    color: $darkgreen
    background-color: lighten($green, 50%)

  &.alert-warning
    color: $darkyellow
    background-color: $yellow

  &.alert-info
    color: $darkturqoisish
    background-color: $turquoisish

  &.alert-danger
    color: $white
    background-color: $lightred