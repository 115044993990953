.meta-nav
  .col
    display: flex
    @include media-breakpoint-down(md)
      flex-basis: auto
      margin-bottom: 20px
      max-width: 50%
    @include media-breakpoint-down(xs)
      max-width: 100%
    h3
      +font-size(2)
      color: $white
      margin-bottom: 0
      text-align: center

    .box
      background-color: $green
      padding: 2.6rem 2.4rem 2.6rem 2.4rem
      width: 100%
      i
        padding-right: 1rem

    a
      display: block
      color: $white
      text-decoration: none

.meta-nav.english
  margin-top: 30px
  margin-bottom: 60px  
  .col
    h3
      +font-size(1.8)
      color: $white
      margin-bottom: 0
      text-align: center