@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-Bold-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-Bold-Web.woff) format("woff"),url(../fonts/Graphik-Bold-Web.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-BoldItalic-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-BoldItalic-Web.woff) format("woff"),url(../fonts/Graphik-BoldItalic-Web.woff2) format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-RegularItalic-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-RegularItalic-Web.woff) format("woff"),url(../fonts/Graphik-RegularItalic-Web.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-Regular-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-Regular-Web.woff) format("woff"),url(../fonts/Graphik-Regular-Web.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Plantin-Web";
  src: url(../fonts/360D0A_0_0.eot) format("embedded-opentype"),url(../fonts/360D0A_0_0.woff) format("woff"),url(../fonts/360D0A_0_0.woff2) format("woff2"),url(../fonts/360D0A_0_0.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Graphik-Web",sans-serif;
  color: #3f3f3f;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.45;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 1.2rem;
  font-size: 20px;
  font-size: 2rem;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1,
h2 {
  font-size: 52px;
  font-size: 5.2rem;
  line-height: 1.1667;
  margin: 3rem 0 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

h1.apply-h2-style {
  font-size: 40px;
  font-size: 4rem;
}

@media (max-width: 991px) {
  h1.apply-h2-style {
    font-size: 30px;
    font-size: 3rem;
    margin: 2rem 0 1rem;
  }
}

h1.apply-h3-style {
  font-size: 26px;
  font-size: 2.6rem;
}

@media (max-width: 991px) {
  h1.apply-h3-style {
    font-size: 20px;
    font-size: 2rem;
  }
}

h2 {
  font-size: 40px;
  font-size: 4rem;
}

@media (max-width: 991px) {
  h2 {
    font-size: 30px;
    font-size: 3rem;
    margin: 2rem 0 1rem;
  }
}

h3 {
  font-size: 26px;
  font-size: 2.6rem;
}

@media (max-width: 991px) {
  h3 {
    font-size: 20px;
    font-size: 2rem;
  }
}

h3.line {
  position: relative;
  z-index: 1;
}

h3.line:after {
  border-top: 2px solid #48c0b1;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

h3.line span {
  background: #f3f3f3;
  float: none !important;
  font-size: inherit !important;
  padding: 0 15px 0 0 !important;
}

h4 {
  font-size: 20px;
  font-size: 2rem;
}

h5 {
  font-size: 20px;
  font-size: 2rem;
}

h6 {
  font-size: 20px;
  font-size: 2rem;
}

q {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.2;
  font-weight: 600;
}

cite {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 400;
}

a {
  color: #14aa96;
  text-decoration: underline;
}

a:hover {
  color: #48c0b1;
}

.lead {
  font-size: 1.2em;
  font-weight: 400;
}

.spacey {
  letter-spacing: .1em;
}

.card {
  display: inline-block;
  hyphens: auto;
}

@media (max-width: 767.98px) {
  .help-content h1 {
    font-size: 30px;
    font-size: 3rem;
  }
}

.help-content h2 {
  margin-bottom: .2em;
}

@media (min-width: 992px) {
  .help-content h2 {
    font-size: 36px;
    font-size: 3.6rem;
  }
}

.help-content h3 {
  margin: .6em 0 .4em;
}

@media (min-width: 992px) {
  .help-content h3 {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

#content404 {
  background-color: #14aa96;
}

#content404 a {
  color: #fff;
}

#content404 p {
  margin: 25px auto;
}

#content404 p img {
  margin-left: -17%;
  max-width: 100%;
}

#content404 p:first-of-type {
  margin-top: 50px;
}

#content404 p:last-of-type {
  margin-bottom: 50px;
}

html {
  min-height: 100%;
}

html body {
  min-height: 100%;
  background-color: #f3f3f3;
  margin: 2rem;
}

@media (max-width: 991.98px) {
  html body {
    margin: 1rem;
  }
}

html body section {
  margin-bottom: 5rem;
}

#wrap-main {
  min-height: 100%;
}

main {
  overflow: auto;
}

.help-content main {
  overflow: unset;
}

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
}

#help-body {
  margin-bottom: 0px;
}

.slider {
  margin-bottom: 8rem;
  color: #fff;
}

.slider .defaultslide div.content-row {
  padding-bottom: 8rem;
}

.slider .header-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .8em;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .slider .header-image-copyright {
    bottom: 25rem;
  }
}

.slider .row {
  position: relative;
}

.slider .row .textbox {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  color: #3f3f3f;
  padding: 3rem;
  min-width: 350px;
  max-width: 400px;
  opacity: .85;
  color: #fff;
}

.slider .row .textbox a,
.slider .row .textbox p,
.slider .row .textbox h3 {
  color: #fff;
}

@media (max-width: 991.98px) {
  .slider .row .textbox {
    position: inherit;
    padding: 2rem;
    max-width: 100%;
  }
}

.slider .row h3 {
  margin-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .slider .row h3 {
    margin-bottom: 0;
  }
}

.slider .boxslide .row {
  margin-bottom: -4rem;
}

.slider .owl-carousel {
  z-index: 0;
}

.slider .owl-carousel .owl-stage {
  display: flex;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item {
  display: flex;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item {
  margin-bottom: 4rem;
  min-height: 30vw;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item.bg-color {
  background-color: #48c0b1;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide .header-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 15rem;
  transform-origin: right;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide .header-image-copyright {
    bottom: 25rem;
    right: 1.5rem;
  }
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item {
  align-items: center;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row {
  height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img img {
    bottom: -25px;
    max-height: 100%;
    position: absolute;
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .text,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .text {
  align-self: center;
}

.slider .owl-carousel .owl-nav {
  margin: -3rem;
  padding: 0;
  background-color: #9acd32;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-nav {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-prev,
.slider .owl-carousel .owl-nav .owl-next {
  margin-top: -2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.slider .owl-carousel .owl-nav .owl-prev {
  right: 96%;
  background-image: url("../img/pfeil-links.svg");
  background-color: rgba(0,0,0,0);
}

.slider .owl-carousel .owl-nav .owl-next {
  left: 96%;
  background-image: url("../img/pfeil-rechts.svg");
  background-color: rgba(0,0,0,0);
}

.slider .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

.slider .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-dot span {
    margin: 2rem 1rem;
  }
}

.slider .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

.slider .owl-carousel .owl-dots {
  position: absolute;
  bottom: 9rem;
  left: 50%;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-dots {
    display: block;
  }
}

.slider .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-dots.disabled {
    display: block;
  }
}

#header2 {
  color: #fff;
  background-color: rgba(0,0,0,0);
}

#header2 .owl-carousel {
  z-index: 0;
}

#header2 .owl-carousel .item {
  align-items: stretch;
  margin-bottom: 4rem;
}

#header2 .owl-carousel .item h2 {
  font-size: 40px;
  font-size: 4rem;
  padding-right: 8rem;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .item h2 {
    font-size: 30px;
    font-size: 3rem;
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

#header2 .owl-carousel .box-alert {
  background-color: #e00b26;
  width: 35%;
  float: left;
  padding: 6rem 8rem;
  position: relative;
}

#header2 .owl-carousel .box-alert .alert-message {
  position: absolute;
  bottom: 6rem;
}

#header2 .owl-carousel .box-alert .alert-message .fa-exclamation-square {
  font-size: 60px;
  font-size: 6rem;
  margin-bottom: 2rem;
}

#header2 .owl-carousel .box-info {
  background-color: #949494;
  width: 65%;
  float: right;
  padding: 6rem 8rem;
  position: relative;
}

#header2 .owl-carousel .box-info .box-message {
  position: absolute;
  bottom: 0;
}

#header2 .owl-carousel .box-info .box-message .row {
  position: relative;
  padding-bottom: 2rem;
}

#header2 .owl-carousel .box-info .box-message .row .textbox {
  background-color: #fff;
  color: #3f3f3f;
  padding: 2.4rem;
  position: absolute;
  bottom: -2rem;
  right: 8rem;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .box-info .box-message .row .textbox {
    position: inherit;
    padding: 2rem;
  }
}

#header2 .owl-carousel .box-info .box-message .row .textbox h3 {
  margin-bottom: 0;
}

#header2 .owl-carousel .owl-nav {
  display: inherit;
  margin: -3rem;
  padding: 0;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .owl-nav {
    display: none;
  }
}

#header2 .owl-carousel .owl-nav .owl-prev,
#header2 .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
}

#header2 .owl-carousel .owl-nav .owl-prev {
  right: 96%;
}

#header2 .owl-carousel .owl-nav .owl-next {
  left: 96%;
}

#header2 .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

#header2 .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

#header2 .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  #header2 .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

#header2 .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

#header3 {
  color: #fff;
  background-color: rgba(0,0,0,0);
}

#header3 .owl-carousel {
  z-index: 0;
}

#header3 .owl-carousel .item {
  align-items: stretch;
}

#header3 .owl-carousel .item h2 {
  font-size: 40px;
  font-size: 4rem;
  padding-right: 8rem;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

#header3 .owl-carousel .item .box-left {
  background-color: #3f3f3f;
  width: 50%;
  float: left;
  padding: 6rem 8rem;
  position: relative;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item .box-left {
    padding: 4rem;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left {
    padding: 2rem;
  }
}

#header3 .owl-carousel .item .box-left .box-content {
  position: absolute;
  bottom: 6rem;
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left .box-content {
    bottom: 0;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left .box-content h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

#header3 .owl-carousel .item .box-right {
  background-color: #949494;
  width: 50%;
  float: right;
  padding: 6rem 8rem;
  position: relative;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item .box-right {
    padding: 4rem;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right {
    padding: 2rem;
  }
}

#header3 .owl-carousel .item .box-right .box-content {
  position: absolute;
  bottom: 6rem;
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right .box-content {
    bottom: 0;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right .box-content h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

#header3 .owl-carousel .owl-stage-outer {
  overflow: hidden;
}

#header3 .owl-carousel .owl-nav {
  display: inherit;
  margin: -3rem;
  padding: 0;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .owl-nav {
    display: none;
  }
}

#header3 .owl-carousel .owl-nav .owl-prev,
#header3 .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
}

#header3 .owl-carousel .owl-nav .owl-prev {
  right: 96%;
}

#header3 .owl-carousel .owl-nav .owl-next {
  left: 96%;
}

#header3 .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

#header3 .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

#header3 .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  #header3 .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

#header3 .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

#header4 a {
  color: #3f3f3f;
  text-decoration: none;
}

#header4 a .card {
  border-radius: 0;
  position: relative;
  border: none;
}

#header4 a img {
  height: 100%;
  width: 100%;
}

#header4 a .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

#header4 a .card-footer .date {
  float: left;
}

#header4 a .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  #header4 a .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

#header4 a .badge {
  position: absolute;
  top: -32px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
}

#header4 a .badge.badge-primary {
  background-color: #14aa96;
  text-transform: uppercase;
  font-weight: 500;
}

.events-box {
  background-color: #545454;
  color: #fff;
  padding: 40px;
  width: 320px;
}

.events-box h4 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.events-box h4:first-of-type:before {
  content: url(../img/seminare.svg);
  margin-right: 10px;
}

.events-box h4:last-of-type:before {
  content: url(../img/webinare.svg);
  margin-right: 10px;
}

.events-box .event {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.events-box .event:last-of-type {
  border: none;
}

.events-box .event h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.events-box .event h5 span {
  font-size: 12px;
  line-height: 16px;
}

body footer {
  background-color: #3f3f3f;
  color: #fff;
  position: relative;
  margin-bottom: 20px;
  clear: both;
}

@media (max-width: 767.98px) {
  body footer div.main-footer-bottom ul.inline-nav {
    text-align: center;
  }

  body footer div.main-footer-bottom ul.inline-nav li {
    display: inline !important;
  }
}

body footer .pre-footer {
  background-color: #3f3f3f;
  padding: 1.6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (max-width: 767.98px) {
  body footer .pre-footer .col-md-4,
  body footer .pre-footer .col-md-3 {
    padding-bottom: 35px;
  }
}

body footer .pre-footer .pre-footer-image {
  height: 3rem;
  filter: brightness(0) invert(1);
}

body footer .pre-footer a {
  color: hsla(0,0%,100%,.75);
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

body footer .pre-footer a:hover {
  text-decoration: none;
  color: #fff;
}

body footer .pre-footer-logo-container {
  padding-bottom: 4rem;
}

body footer .main-footer {
  padding: 1.6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

body footer .main-footer nav.more-brands ul.inline-nav li {
  padding: .5rem 1rem;
  display: inline-block;
}

@media (max-width: 767.98px) {
  body footer .main-footer {
    text-align: center;
  }
}

@media (max-width: 767.98px)and (max-width: 575px) {
  body footer .main-footer ul.inline-nav {
    text-align: left;
  }
}

@media (max-width: 767.98px) {
  body footer .main-footer nav.more-brands {
    text-align: left;
  }
}

@media (min-width: 576px) {
  body footer {
    height: auto;
  }
}

body footer .row {
  position: relative;
}

body footer .row .brand-text {
  font-weight: 900;
}

body footer .row img {
  display: inline-block;
  height: 1.4em;
  vertical-align: top;
}

body footer nav.more-brands {
  position: relative;
  padding: 1.6rem;
  background: #e00b26;
  margin-top: 1em;
}

@media (max-width: 767.98px) {
  body footer nav.more-brands li {
    display: block;
    width: 100%;
  }
}

body footer nav.footer-nav {
  margin-top: 1em;
  text-align: center;
}

body footer nav.footer-nav a {
  font-size: 14px;
  color: #fff;
}

@media (min-width: 768px) {
  body footer nav.footer-nav {
    margin-top: 0;
    text-align: right;
  }

  body footer nav.footer-nav ul.inline-nav li:last-of-type {
    padding-right: 0;
  }
}

body footer nav.footer-nav ul li a,
body footer nav.more-brands ul li a {
  color: hsla(0,0%,100%,.75);
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

body footer nav.footer-nav ul li a:hover,
body footer nav.more-brands ul li a:hover {
  text-decoration: none;
  color: #fff;
}

@media (max-width: 767.98px) {
  body footer .copy {
    text-align: center;
  }
}

body footer .social {
  margin-top: 1rem;
}

body footer .social .icons {
  float: right;
  text-align: right;
}

@media (max-width: 767.98px) {
  body footer .social .icons {
    float: none;
    text-align: center !important;
  }
}

body footer .social a {
  color: #fff;
  margin-right: 1rem;
  text-decoration: none;
}

@media (max-width: 991.98px) {
  body footer .social a {
    margin: 0;
  }
}

.icon-list .icon-container {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-list.custom-icon-list {
  align-content: center;
  min-height: 44px;
}

.icon-list.custom-icon-list p {
  margin-bottom: 0;
}

table,
.table {
  max-width: 100%;
  width: 100%;
}

table th,
.table th {
  border: 2px solid #f3f3f3;
  border-bottom: 0;
  background-color: rgb(224.5,224.5,224.5);
  padding: .5rem .75rem;
}

table td,
.table td {
  border: 2px solid #f3f3f3;
  background-color: #fff;
  padding: .5rem .75rem;
}

.btn-xls-export {
  margin-left: .2rem;
}

.redactor-content .expand {
  height: 100%;
  width: 100%;
}

.redactor-content .expand i {
  float: right;
  background-color: #48c0b1;
  padding: 1rem;
  color: #fff;
}

.redactor-content .expand svg {
  background-color: #48c0b1;
  color: #fff;
  height: 40px;
  padding: 5px;
  right: 0;
  width: 40px;
}

.redactor-content .table-responsive.expandable {
  position: relative;
}

.redactor-content .table-responsive.expandable table {
  margin-bottom: unset;
}

section.table,
div.table {
  font-size: 1.33rem;
}

section.table .expand,
div.table .expand {
  height: 100%;
  width: 100%;
}

section.table .expand i,
div.table .expand i {
  float: right;
  background-color: #48c0b1;
  padding: 1rem;
  color: #fff;
}

section.table .expand svg,
div.table .expand svg {
  background-color: #48c0b1;
  color: #fff;
  height: 40px;
  padding: 5px;
  right: 0;
  width: 40px;
}

section.table .fa-expand-arrows,
div.table .fa-expand-arrows {
  cursor: pointer;
}

section.table .table-responsive.expandable,
div.table .table-responsive.expandable {
  cursor: unset !important;
  position: relative;
}

section.table .table-responsive.expandable table,
div.table .table-responsive.expandable table {
  cursor: unset !important;
  margin-bottom: unset;
}

section.table .table-responsive.expandable:hover,
div.table .table-responsive.expandable:hover {
  cursor: pointer;
}

section.table .table-responsive.expandable:hover .expand,
div.table .table-responsive.expandable:hover .expand {
  display: block;
}

table.events-overview {
  border-spacing: 0 10px;
  border-collapse: separate;
}

table.events-overview .event-radio-td input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

table.events-overview .event-radio-td img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
  border: 1px solid #666;
}

table.events-overview .event-radio-td input:checked+img {
  background: url(../img/check_box.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

table.events-overview tr th {
  background-color: rgb(224.5,224.5,224.5);
  border-top: none;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 576px) {
  table.events-overview tr th {
    font-size: 12px;
    padding: 10px 50px;
  }
}

table.events-overview tr th span {
  display: block;
  font-size: 36px;
  font-weight: 700;
  line-height: 26px;
}

table.events-overview tr td {
  background-color: #fff;
  border-top: none;
  vertical-align: middle;
}

@media (min-width: 576px) {
  table.events-overview tr td {
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  table.events-overview tr td {
    font-size: 1rem;
  }
}

table.events-overview tr td strong {
  display: block;
}

table.events-overview tr td span.dot {
  display: block;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  line-height: 20px;
}

table.events-overview tr td span.dot.green {
  color: #6fcf97;
}

table.events-overview tr td span.dot.yellow {
  color: #f2c94c;
}

table.events-overview tr td span.dot.red {
  color: #eb5757;
}

table.events-overview tr select {
  background: url(../img/angle-down.svg) no-repeat right center #fff;
  border: 0;
  outline: 0;
  padding-right: 15px;
  -webkit-appearance: none;
}

table.events-overview tr select:hover {
  cursor: pointer;
}

table.subscriptions-options {
  border-spacing: 0 10px;
  border-collapse: separate;
  width: 100%;
}

table.subscriptions-options.discount th {
  background-color: #14aa96;
  color: #fff;
  text-indent: 8px;
  padding: .8rem;
}

table.subscriptions-options.discount tr td {
  border-top: 2px solid #14aa96;
  border-bottom: 2px solid #14aa96;
}

table.subscriptions-options.discount tr td:first-of-type {
  border-left: 2px solid #14aa96;
}

table.subscriptions-options.discount tr td:last-of-type {
  border-right: 2px solid #14aa96;
}

table.subscriptions-options.discount .price span {
  color: #14aa96;
  font-weight: 600;
  text-decoration: line-through;
  display: block;
}

@media (max-width: 991.98px) {
  table.subscriptions-options.discount .price span {
    font-size: 2rem;
  }
}

table.subscriptions-options tr td {
  background-color: #fff;
  padding: 1.6rem;
}

table.subscriptions-options tr td .form-check-label {
  margin-bottom: .6666666667rem;
  padding-left: 2.24rem;
}

table.subscriptions-options tr span {
  display: block;
}

table.subscriptions-options tr strong {
  display: block;
}

table.subscriptions-options tr .price span {
  font-size: 3rem;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  table.subscriptions-options tr .price span {
    font-size: 2rem;
  }
}

table.subscriptions-options tr select {
  background: url(../img/angle-down.svg) no-repeat 90% center #fff;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .8rem 3rem .8rem .8rem;
  -webkit-appearance: none;
}

.modal.show:hover {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .modal.show {
    pointer-events: none;
  }
}

.modal.show.show {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 50px !important;
}

.modal.show.show .closer svg {
  color: #fff;
  float: right;
  height: 40px;
  width: 40px;
}

.category-hero-left:after,
.category-hero-right:after {
  content: "";
  display: table;
  clear: both;
}

.category-hero-left .row,
.category-hero-right .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 991.98px) {
  .category-hero-left .row,
  .category-hero-right .row {
    margin-bottom: 0;
  }
}

.category-hero-left .row a,
.category-hero-right .row a {
  color: #3f3f3f;
  text-decoration: none;
}

.category-hero-left .row a .card,
.category-hero-right .row a .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
}

.category-hero-left .row a .card .video-head,
.category-hero-right .row a .card .video-head {
  position: relative;
}

.category-hero-left .row a .card img,
.category-hero-right .row a .card img {
  height: auto;
  width: 100%;
}

.category-hero-left .row a .card .card-footer,
.category-hero-right .row a .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.category-hero-left .row a .card .card-footer .date,
.category-hero-right .row a .card .card-footer .date {
  float: left;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: left;
  }
}

.category-hero-left .row a .card .card-footer .comments,
.category-hero-right .row a .card .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: right;
  }
}

.category-hero-left .row a .card.hero,
.category-hero-right .row a .card.hero {
  height: calc(100% - 1.2rem);
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero,
  .category-hero-right .row a .card.hero {
    height: auto;
  }
}

.category-hero-left .row a .card.hero img,
.category-hero-right .row a .card.hero img {
  height: 662px;
}

@media (max-width: 1199.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 595px;
  }
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 510px;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: auto;
  }
}

body>.alert {
  margin-bottom: 0;
}

.alert {
  border-radius: 0;
  border: none;
  padding: 1rem;
  margin-bottom: 0;
}

.alert p:last-of-type {
  margin-bottom: 0;
}

.alert.alert-success {
  color: #0c6054;
  background-color: hsl(172,78.9473684211%,87.2549019608%);
}

.alert.alert-warning {
  color: #937300;
  background-color: #ffc800;
}

.alert.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
}

.alert.alert-danger {
  color: #fff;
  background-color: #e64155;
}

dl {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

dl dt,
dl dd {
  padding: 1.6rem;
  margin: 0 0 .2em;
}

dl dt {
  background-color: rgba(63,63,63,.1);
}

dl dd {
  background-color: #fff;
}

dl dd code {
  color: #3f3f3f;
}

.image-text-overlay {
  position: relative;
}

.image-text-overlay .copyright-text {
  position: absolute;
  display: block;
  color: #fff;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .6em;
  padding: .25em;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .image-text-overlay .copyright-text {
    bottom: 10rem;
  }
}

@media (max-width: 991.98px) {
  .image-text-overlay.col-testimonial-image .copyright-text {
    bottom: 0rem;
  }
}

.figcaption {
  font-size: .7em;
}

.accordion .card {
  border: none;
  background: rgba(0,0,0,0);
  width: 100%;
}

.accordion .card .card-header {
  background-color: #fff;
  border-radius: 0;
  border-bottom: 0;
  margin-bottom: 1rem;
}

.accordion .card .card-header:hover {
  cursor: pointer;
}

.accordion .card .card-header:hover h5 {
  color: #48c0b1;
}

.accordion .card .card-header h5:active {
  background: none;
  border: none;
  box-shadow: none !important;
}

.accordion .card .card-header h5:before {
  content: url("../img/plus.svg");
  padding-right: .8rem;
  position: relative;
  top: .3rem;
}

.accordion .card .card-header.active h5:before {
  content: url("../img/minus.svg");
}

.accordion .card img.img-fluid {
  margin-bottom: 1rem;
}

.accordion .card .card-employee {
  position: relative;
}

.accordion .card .card-employee .card-body {
  background-color: #fff;
  padding: 1.6rem;
}

.accordion .card .card-employee .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.accordion .card .card-employee .card-footer img {
  width: 20px;
  margin-right: .6666666667rem;
}

.accordion .card .card-apply {
  background-color: #14aa96;
  background-image: url("../img/person-placeholder-icon.svg");
  background-position: -1rem -4rem;
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100% - 2rem);
  position: relative;
}

@media (max-width: 767.98px) {
  .accordion .card .card-apply {
    height: 100%;
  }
}

.accordion .card .card-apply .card-body {
  position: absolute;
  bottom: 0;
  padding: 1.6rem;
}

@media (max-width: 767.98px) {
  .accordion .card .card-apply .card-body {
    position: inherit;
  }
}

.accordion .card .card-apply .card-body p,
.accordion .card .card-apply .card-body h4 {
  color: #fff;
}

.accordion .card .card-apply .card-body .text-muted {
  color: #fff !important;
}

.accordion .card .show {
  margin-bottom: 1rem;
}

.accordion .flip .card {
  margin-bottom: 2rem;
}

.accordion .flip .card .face .inner {
  min-height: 376px;
}

.accordion .flip .card .face.front {
  background-color: #fff;
}

.accordion .flip .card .face.back {
  background-color: #949494;
  color: #fff;
}

.accordion .flip .card .face.back .inner {
  padding: 1.6rem;
}

.accordion .flip .card .face.back .inner .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.accordion .flip .card .face.back .inner .card-footer img {
  width: 20px;
  margin-right: .6666666667rem;
}

.accordion .flip .card .face.back .inner a:hover {
  color: #3f3f3f;
  cursor: pointer;
}

.btn {
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 0;
  border: 0;
  outline: 0;
  padding: .35em .8em;
  background-color: rgba(0,0,0,0);
  text-decoration: none;
}

@media (max-width: 575.98px) {
  .btn {
    white-space: normal;
  }
}

.btn.btn-lg {
  font-size: 1.2em;
  padding: .5em 1em;
}

.btn.btn-default {
  font-size: 14px;
  font-size: 1.4rem;
  color: #3f3f3f;
  text-transform: uppercase;
  padding: .25em .5em;
  margin: .5em -0.25em;
  letter-spacing: .1em;
}

.btn.btn-default:hover,
.btn.btn-default:active,
.btn.btn-default:focus,
.btn.btn-default:active:focus {
  color: #48c0b1;
}

@media (max-width: 991.98px) {
  .btn.btn-default {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .btn.btn-default {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn.btn-default-neg {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  padding: .25em .5em;
  margin: .5em -0.25em;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.btn.btn-default-neg:hover,
.btn.btn-default-neg:active,
.btn.btn-default-neg:focus,
.btn.btn-default-neg:active:focus {
  color: #f3f3f3;
}

@media (max-width: 991.98px) {
  .btn.btn-default-neg {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .btn.btn-default-neg {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn.btn-primary {
  color: #3f3f3f;
  padding: 0;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:active:focus {
  color: #48c0b1;
  background-color: rgba(0,0,0,0);
}

.btn.btn-secondary {
  color: #fff;
  background-color: #14aa96;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:active:focus {
  background-color: hsl(172.5,48.7804878049%,61.7647058824%);
  color: #fff;
}

.btn.btn-tertiary {
  color: #fff;
  background-color: #48c0b1;
}

.btn.btn-tertiary:hover,
.btn.btn-tertiary:active,
.btn.btn-tertiary:focus,
.btn.btn-tertiary:active:focus {
  background-color: hsl(172.5,48.7804878049%,61.7647058824%);
  color: #fff;
}

.btn:focus {
  box-shadow: none;
}

.btn:active {
  box-shadow: 0 0 0 .1em rgba(20,170,150,.9) !important;
}

.btn.btn-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
}

ul.inline-nav {
  margin: 0;
  padding: 0;
}

ul.inline-nav li {
  list-style: none;
  margin: 0;
  display: inline;
  padding: 1rem;
}

body header .termine-uebersicht {
  background-color: #888;
  color: #fff;
}

body header .termine-uebersicht:hover {
  color: unset;
}

body header .navbar-brand img {
  height: 2.4rem;
  width: auto;
}

@media (max-width: 575.98px) {
  body header .navbar-brand img {
    height: 1.8rem;
  }
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  body header .navbar-brand img {
    width: 100%;
  }
}

body header #loginBox h2 {
  font-size: 2em;
}

body header #loginBox form {
  margin: 2rem 0;
}

body header #loginBox form .form-control {
  padding: .5em 1em;
  margin-bottom: 1rem;
  height: 40px;
}

body header #loginBox form .input-group-append {
  height: 40px;
}

body header #loginBox form .input-group-append .input-group-text {
  border: none;
}

body header #loginBox form a {
  margin-left: 1rem;
}

body header #loginBox form .btn-secondary {
  width: 50%;
}

body header #loginBox form .form-check {
  margin-top: 1rem;
}

body header #loginBox .register .btn-secondary {
  width: 100%;
}

body header label[for=search] {
  margin: 0;
}

body header #searchBox {
  background-color: #48c0b1;
  color: hsla(0,0%,100%,.5);
}

body header #searchBox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: hsla(0,0%,100%,.5);
  border: 0;
  font-size: 1.6em;
  padding-left: 0;
}

body header #searchBox form input.form-control {
  background-color: rgba(0,0,0,0);
  color: #fff;
}

body header #searchBox form input.form-control:active,
body header #searchBox form input.form-control:focus {
  box-shadow: none;
}

body header #searchBox form input.form-control::placeholder {
  color: hsla(0,0%,100%,.5);
}

body header>nav.navbar {
  background-color: #fff;
  padding: 1.6rem;
  font-size: 14px;
  font-size: 1.4rem;
}

body header>nav.navbar ul.navbar-nav li a {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

body header>nav.navbar ul.navbar-nav li a.nav-link {
  color: #3f3f3f;
  text-decoration: none;
  padding: 1rem;
  font-size: 1.6rem;
}

body header>nav.navbar ul.navbar-nav li a.nav-link:hover,
body header>nav.navbar ul.navbar-nav li a.nav-link:active,
body header>nav.navbar ul.navbar-nav li a.nav-link:focus,
body header>nav.navbar ul.navbar-nav li a.nav-link:active:focus {
  color: #545454;
}

body header>nav.navbar ul.navbar-nav li a.nav-link.dropdown-toggle:after {
  width: 1.5em;
  height: 1em;
  margin: 0;
  line-height: 1;
  vertical-align: -0.1em;
  border: 0;
  background-image: url("../img/angle-down.svg");
  background-position: center;
  background-repeat: no-repeat;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu {
  font-size: 1em;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu.show {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item {
  text-decoration: none;
  padding: 1.6rem;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.hover,
body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item:hover {
  background-color: rgb(224.5,224.5,224.5);
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.active,
body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item:active {
  background-color: #14aa96;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.active:hover {
  background-color: hsl(172.5,48.7804878049%,61.7647058824%);
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu .dropdown-divider {
  display: none;
}

body header>nav.navbar .navbar-toggler {
  border: none;
  padding: 1.6rem;
}

body header>nav.navbar .navbar-collapse.collapse {
  margin-right: 16px;
}

@media (min-width: 992px) {
  body header>nav.navbar .navbar-collapse.collapse {
    order: 1;
  }
}

@media (max-width: 767.98px) {
  body header>nav.navbar .container-fluid {
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  body header>nav.navbar .navbar-wrapper {
    order: 2;
  }
}

body header .bg-light {
  background-color: #fff !important;
}

.navbar-wrapper.language-switch {
  margin-left: 10px;
}

@media (max-width: 767.98px) {
  .navbar-wrapper.language-switch {
    margin-left: unset;
  }
}

.breadcrumb {
  padding: 4rem 0rem .75rem 0rem;
  background-color: rgba(0,0,0,0);
}

@media (max-width: 767.98px) {
  .breadcrumb {
    padding: 1rem 0 .75rem;
  }
}

.breadcrumb .breadcrumb-item a {
  color: #444;
}

.breadcrumb .breadcrumb-item.active a {
  color: #e5e5e5;
}

.content-support .breadcrubs li {
  color: #6c757d;
}

form .expand-hidden-field {
  margin-left: 2.7rem;
}

form button {
  background: #14aa96;
  width: 100%;
  padding: 9px;
  color: #fff;
}

form .form-control {
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 0;
  border: none;
  padding: .5em 1em;
  height: auto;
}

form .btn.btn-secondary {
  width: 100%;
}

form a.active {
  color: #14aa96;
}

form a.active .box {
  border: 2px solid #14aa96;
}

form a .box {
  background-color: #fff;
  padding: 2rem;
}

form a span {
  float: left;
  margin-right: 1.6rem;
  font-size: 3.6rem;
  margin-top: -0.1em;
}

form p {
  margin: 2rem 0;
}

form .form-check .form-check-label {
  margin-bottom: 1rem;
  padding-left: .8rem;
}

form .form-check p {
  margin-top: 0px;
  margin-bottom: 0px;
}

form select {
  background: url(../img/angle-down.svg) no-repeat 95% center #fff;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .7rem;
  -webkit-appearance: none;
}

@-moz-document url-prefix()  {
  form select {
    background: none;
  }
}

input#preferredKitten {
  display: none;
}

.registration-form-deactivated {
  width: 100%;
}

#vlb-form button {
  background: #14aa96;
  width: 100%;
  padding: 9px;
  color: #fff;
}

.form-success {
  padding: 5rem;
  border-bottom: solid 4px #14aa96;
}

.border-red-400 {
  border: 1px solid #e64155;
}

.text-red-400 {
  color: #e64155;
}

.flip {
  -webkit-perspective: 800px;
  perspective: 800px;
  position: relative;
}

.flip:hover .card {
  -moz-transform: rotatey(-180deg);
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}

.flip .card {
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: .5s;
  transform-style: preserve-3d;
  transition: .5s;
  transition-delay: .25s;
}

.flip .card .face {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip .card .front {
  position: absolute;
  width: 100%;
}

.flip .card .back {
  -moz-transform: rotatey(-180deg);
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}

.flip .card .inner {
  position: relative;
  margin: 0;
}

.flip .card .inner .abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

#modelle-overview a:focus,
#modelle-overview a:hover {
  text-decoration: none;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .flip .card .face {
    transform-style: preserve-3d;
    transition: .5s;
    transition-delay: .25s;
  }

  .flip .card .face.back {
    visibility: hidden;
  }

  .flip:hover .card {
    transform: rotatey(0deg);
  }

  .flip:hover .card .face.front {
    transform: rotatey(-180deg);
    visibility: hidden;
  }

  .flip:hover .card .face.back {
    transform: rotatey(0deg);
    visibility: visible;
  }
}

@media all and (min-width: 0\0 )and (min-resolution: 0.001dpcm) {
  .flip .card .face.back {
    visibility: hidden;
  }

  .flip:hover .card .face.front {
    visibility: hidden;
  }

  .flip:hover .card .face.back {
    visibility: visible;
  }
}

#intro-section .owl-dots {
  display: none;
}

#intro-section .single-item {
  height: 600px;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
}

.rich-text:after {
  content: "";
  display: table;
  clear: both;
}

.rich-text img {
  max-width: 100%;
  height: auto !important;
  margin: 0 !important;
  padding-right: 2rem;
  padding-left: 0;
}

.video {
  margin: 4rem;
}

.subscriptions-options {
  background-color: #fff;
  padding: 1.6rem 0;
  margin: 0 0 2rem;
}

.subscriptions-options strong {
  display: block;
  margin-bottom: 1em;
}

.subscriptions-options h4 {
  background-color: #14aa96;
  color: #fff;
  text-indent: 8px;
  padding: .8rem;
  display: block;
  width: 100%;
}

@media (max-width: 575.98px) {
  .subscriptions-options h4 {
    text-indent: 0;
  }
}

.subscriptions-options h4 i {
  font-size: 14px;
  font-weight: lighter;
}

.subscriptions-options .price span {
  font-size: 3rem;
  font-weight: 600;
  display: block;
}

@media (max-width: 991.98px) {
  .subscriptions-options .price span {
    font-size: 2rem;
  }
}

.subscriptions-options .form-check-label {
  margin-bottom: .6666666667rem;
  padding-left: 2.4rem;
}

.subscriptions-options .form-check-label span {
  display: block;
}

.subscriptions-options .form-check-label strong {
  display: block;
}

.subscriptions-options .form-check-input {
  margin-left: 0;
}

.subscriptions-options.discount {
  border: 2px solid #14aa96;
  padding: 0;
}

.subscriptions-options.discount .price span {
  color: #14aa96;
  font-size: 3rem;
  font-weight: 600;
  text-decoration: line-through;
  display: block;
}

@media (max-width: 991.98px) {
  .subscriptions-options.discount .price span {
    font-size: 2rem;
  }
}

.subscriptions-options select {
  background: url(../img/angle-down.svg) no-repeat 90% center #fff;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .8rem 3rem .8rem .8rem;
  -webkit-appearance: none;
}

.subscriptions-options #new-calculated-cost {
  color: #000;
  text-decoration: none;
  margin-top: -32px;
}

#search-page-heading {
  background: #fff;
  padding: 15px;
  margin-top: 40px;
}

.search-page-result-box {
  background: #fff;
  padding: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-page-result-box a {
  overflow-wrap: break-word;
}

.search-page-result-box img {
  display: none;
}

.search-page-result-box .search-thumbnail {
  display: block;
  width: 100%;
}

.slider {
  color: #fff;
  margin-bottom: 2.5rem;
}

.slider .row {
  position: relative;
}

.slider .row .textbox {
  position: absolute;
  background-color: #fff;
  color: #3f3f3f;
  padding: 3rem;
}

@media (max-width: 991.98px) {
  .slider .row .textbox {
    position: inherit;
    padding: 2rem;
  }
}

.slider .row h3 {
  margin-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .slider .row h3 {
    margin-bottom: 0;
  }
}

.slider .owl-carousel {
  z-index: 0;
}

.slider .owl-carousel .owl-stage {
  display: flex;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage::after {
  display: none;
}

.slider .owl-carousel .owl-stage .owl-item {
  display: flex;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item {
  margin-bottom: 6rem;
  min-height: 60vh;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  background-color: #14aa96;
  background-size: cover;
}

.slider .owl-carousel .owl-stage .owl-item .item.top {
  background-position: top;
}

.slider .owl-carousel .owl-stage .owl-item .item.center {
  background-position: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.bottom {
  background-position: bottom;
}

.slider .owl-carousel .owl-stage .owl-item .item.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(63, 63, 63, 0.5));
  mix-blend-mode: normal;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide {
  position: relative;
  align-items: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 180%);
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row {
  height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img img {
    bottom: -3rem;
    max-height: 100%;
    position: absolute;
    width: auto;
  }
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .text,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .text {
  align-self: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .text div,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .text div {
  font-size: 3rem;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide.light,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide.light a.btn {
  color: #fff;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide.dark,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide.dark a.btn {
  color: #3f3f3f;
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item .btn {
    text-align: left;
  }
}

.slider .owl-carousel .owl-nav {
  padding: 0;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-nav {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-prev,
.slider .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.slider .owl-carousel .owl-nav .owl-prev.disabled,
.slider .owl-carousel .owl-nav .owl-next.disabled {
  display: none;
}

.slider .owl-carousel .owl-nav .owl-prev {
  right: 95%;
  background-image: url(../img/pfeil-links.svg);
  background-color: rgba(0,0,0,0);
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-nav .owl-prev {
    right: 93%;
  }
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-nav .owl-prev {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-next {
  left: 95%;
  background-image: url(../img/pfeil-rechts.svg);
  background-color: rgba(0,0,0,0);
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-nav .owl-next {
    left: 93%;
  }
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-nav .owl-next {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-dot.active span {
  background-color: #fff;
}

.slider .owl-carousel .owl-nav .owl-dot:focus {
  outline: 0;
}

.slider .owl-carousel .owl-nav .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-nav .owl-dot span {
    margin: 2rem 1rem;
  }
}

.slider .owl-carousel .owl-nav .owl-dot span:hover {
  background-color: #fff;
}

.owl-three button.owl-next:hover {
  color: #999;
}

.owl-three button.owl-prev:hover {
  color: #999;
}

.owl-three .fa-arrow-right {
  font-size: 30px;
  margin-right: 20px;
}

.owl-three .fa-arrow-right:hover {
  color: #999;
}

.owl-three .fa-arrow-left {
  font-size: 30px;
  margin-left: 20px;
}

.owl-three .fa-arrow-left:hover {
  color: #999;
}

.new-testimonial .row-testimonial {
  background-color: #fff;
  margin-right: unset;
  margin-left: unset;
}

.new-testimonial .row-testimonial .col-testimonial-image {
  padding-left: unset;
  padding-right: unset;
}

.new-testimonial .row-testimonial .col-testimonial-image img {
  width: 100%;
}

.new-testimonial .row-testimonial .col-testimonial-content {
  padding: 0 50px;
  padding-top: 25px;
}

@media (max-width: 991.98px) {
  .new-testimonial .row-testimonial .col-testimonial-content {
    min-height: 300px;
    padding-bottom: 25px;
    overflow: scroll;
  }
}

#header1 {
  min-height: 60vh;
}

#header1 h2 {
  hyphens: none !important;
}

@media (max-width: 991.98px) {
  #header1 {
    min-height: 40vh;
  }
}

#header1 .item {
  min-height: 60vh;
}

@media (max-width: 991.98px) {
  #header1 .item {
    min-height: 40vh;
  }
}

.button-all-news {
  position: absolute;
  right: 14px;
  margin-top: 4px;
}

@media (max-width: 991px) {
  .multicol .col {
    flex-basis: auto;
    margin-bottom: 3rem;
    max-width: 50%;
    width: 50%;
  }
}

@media (max-width: 767px) {
  .multicol .col {
    max-width: 100%;
    width: 100%;
  }
}

.multicol img {
  width: 100%;
}

.team-container {
  margin-bottom: 2rem;
}

.team-container .card-block {
  padding: 10px;
  min-height: 160px;
}

.team-container .grid-item {
  float: left;
  padding: 0;
  background: #fff;
  margin-bottom: 20px;
  width: calc((100% - 50px)/3);
}

.team-container .grid-item img.product-logo {
  height: 20px;
}

@media (max-width: 768px) {
  .team-container .grid-item {
    width: calc((100% - 40px)/2);
  }
}

@media (max-width: 600px) {
  .team-container .grid-item {
    width: calc(100% - 20px);
  }
}

.team-container .grid-item .card-block p.card-text-fixed-height {
  height: 12rem;
  overflow: hidden;
}

.team-container .grid-item .card-block a.team-member-email {
  text-decoration: none;
}

.team-container .grid-item .text-more-info {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.meta-nav .col {
  display: flex;
}

@media (max-width: 991.98px) {
  .meta-nav .col {
    flex-basis: auto;
    margin-bottom: 20px;
    max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .meta-nav .col {
    max-width: 100%;
  }
}

.meta-nav .col h3 {
  font-size: 20px;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.meta-nav .col .box {
  background-color: #14aa96;
  padding: 2.6rem 2.4rem 2.6rem 2.4rem;
  width: 100%;
}

.meta-nav .col .box i {
  padding-right: 1rem;
}

.meta-nav .col a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.meta-nav.english {
  margin-top: 30px;
  margin-bottom: 60px;
}

.meta-nav.english .col h3 {
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.events-row {
  margin-bottom: 4rem;
}

.events-row:after {
  content: "";
  display: table;
  clear: both;
}

.events-row .wrapper {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media (max-width: 991.98px) {
  .events-row .wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
  }
}

.events-row .wrapper .item {
  height: 100%;
  min-height: 300px;
  background: #fff;
  border: none;
  padding: 1.6rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .events-row .wrapper .item {
    min-height: 220px;
  }
}

.events-row .wrapper .item .text-muted {
  position: absolute;
  bottom: 3rem;
}

@media (max-width: 991.98px) {
  .events-row .wrapper .item .text-muted {
    bottom: 1.6rem;
  }
}

.events-row .wrapper .item.trust-small {
  background-color: #14aa96;
  background-image: url("../img/quotations.svg");
  background-position: -1rem -4rem;
  background-repeat: no-repeat;
  background-size: 50%;
  color: #fff;
  position: relative;
  margin: -1.6rem;
}

.events-row .wrapper .item.trust-small .text-muted {
  color: #fff !important;
}

.events-row h3 {
  margin-bottom: 1.6rem;
}

.events-row h4 {
  margin-bottom: 2.4rem;
}

.events-row a {
  color: #3f3f3f;
  text-decoration: none;
}

.partner .card {
  border-radius: 0;
  border: none;
  display: flex;
  margin-bottom: 2rem;
}

.partner .card-body {
  background-color: #3f3f3f;
  padding: 0;
}

.partner .card-body .card-title {
  margin-left: 3px;
}

.partner .card-body .btn {
  color: #fff;
  letter-spacing: normal;
  padding: 1.2rem 1.6rem;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 767.98px) {
  .partner .card-body .btn i {
    display: inline;
  }
}

.partner h3 {
  margin-bottom: 1.6rem;
}

.partner .img-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}

@media (min-width: 576px) {
  .partner .img-wrapper {
    height: 140px;
  }
}

.partner .img-wrapper img {
  height: auto;
  width: 100%;
}

.partner.english {
  margin-top: 50px;
  margin-bottom: 50px;
}

.hint .tipbox {
  background-color: #14aa96;
  padding: 1.6rem;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}

.hint strong {
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  top: 1px;
}

.hint span {
  padding-right: 1rem;
  float: left;
  font-size: 2rem;
}

.featured .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 767.98px) {
  .featured .row .col-md-6:first-of-type {
    margin-bottom: 3rem;
  }
}

.featured .row.mb-5 {
  margin-bottom: 1rem !important;
}

.featured h3 {
  margin-bottom: 1.6rem;
}

@media (max-width: 767.98px) {
  .featured .news {
    margin-bottom: 3rem;
  }
}

.featured .news a {
  color: #3f3f3f;
  text-decoration: none;
}

.featured .news .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
  display: inline-block;
}

.featured .news .card h4 {
  margin-bottom: 3rem;
}

.featured .news .card img {
  height: auto;
  width: 100%;
  background-color: #545454;
}

.featured .news .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #545454;
  position: absolute;
  bottom: 1.25rem;
}

.featured .news .card.bgimg {
  color: #fff;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),url("../img/platzhalter.png");
  background-size: cover;
}

.featured .news .card.bgimg h4 {
  margin: 0 3rem 3rem 0;
  position: absolute;
  bottom: 1.6rem;
}

@media (max-width: 991.98px) {
  .featured .news .card.bgimg h4 {
    position: inherit;
  }
}

.featured .news .card.bgimg .text-muted {
  color: #fff !important;
}

.featured .events {
  background-color: #3f3f3f;
  padding: 3rem;
  margin-bottom: 2rem;
}

@media (max-width: 991.98px) {
  .featured .events {
    padding: 1.6rem;
    min-height: 0;
  }
}

@media (max-width: 767.98px) {
  .featured .events {
    margin-bottom: 1rem;
  }
}

.featured .events a {
  text-decoration: none;
  border-bottom: 1px solid #545454;
  display: block;
}

.featured .events a:last-of-type {
  border-bottom: none;
  margin-bottom: .5rem;
}

.featured .events a .card {
  background-color: #3f3f3f;
  color: #fff;
  border-radius: 0;
  border: none;
}

.featured .events a .card h4 {
  margin-bottom: 0;
}

.featured .events a .card .card-body {
  padding: 2rem 0;
}

@media (max-width: 991.98px) {
  .featured .events a .card .card-body {
    padding: 1rem 0;
  }
}

.featured .events a .card .date-info {
  overflow: hidden;
}

.featured .events a span {
  padding-right: 2rem;
  font-size: 50px;
  font-size: 5rem;
  float: left;
  position: relative;
  margin-top: -0.3rem;
}

@media (max-width: 991.98px) {
  .featured .events a span {
    display: none;
  }
}

.featured .events a .text-muted {
  color: #fff !important;
}

.featured .events a small {
  font-size: 1.7rem;
}

.trust .owl-two .owl-stage-outer .owl-stage .owl-item {
  opacity: .6;
}

.trust .owl-two .owl-stage-outer .owl-stage .center {
  zoom: normal !important;
  opacity: 1;
}

.trust .owl-two .owl-stage-outer .owl-stage .center .item {
  zoom: 1.09;
}

@media (max-width: 575.98px) {
  .trust .owl-two .owl-stage-outer .owl-stage .center {
    zoom: 0;
  }
}

.trust .owl-carousel {
  z-index: 0;
}

.trust .owl-carousel .owl-stage {
  display: flex;
  align-items: flex-end;
}

.trust .owl-carousel .item {
  min-height: 25vw;
  align-items: stretch;
  display: flex;
  margin-right: 16px;
  width: 100%;
}

@media (max-width: 1199.98px) {
  .trust .owl-carousel .item {
    min-height: 35vw;
  }
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item {
    min-height: 20vw;
  }
}

.trust .owl-carousel .item .container {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-size: cover;
  color: #fff;
  position: relative;
  padding: 6rem;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item .container {
    padding: 2rem;
  }
}

.trust .owl-carousel .item .container .text-holder {
  position: absolute;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item .container .text-holder {
    position: inherit;
  }
}

.trust .owl-carousel .item blockquote {
  padding: 0 6rem 6rem 0;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .item blockquote {
    padding: 0 2rem 2rem 0;
  }
}

.trust .owl-carousel .item blockquote q {
  font-size: 2.4rem;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .item blockquote q {
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

.trust .owl-carousel .item blockquote cite {
  font-style: normal;
}

.trust .owl-carousel .item blockquote footer {
  background-color: rgba(0,0,0,0);
  padding: 0;
  border-top: 0;
  color: #fff;
}

.trust .owl-carousel .owl-nav {
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .owl-nav {
    display: none;
  }
}

.trust .owl-carousel .owl-nav.disabled {
  display: inline;
}

.trust .owl-carousel .owl-nav .owl-prev,
.trust .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.trust .owl-carousel .owl-nav .owl-prev {
  right: 96%;
  background-image: url("../img/pfeil-links.svg");
  background-color: rgba(0,0,0,0);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.trust .owl-carousel .owl-nav .owl-next {
  left: 96%;
  background-image: url("../img/pfeil-rechts.svg");
  background-color: rgba(0,0,0,0);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.trust .owl-carousel .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

.trust .owl-carousel .owl-dots.disabled {
  display: block;
}

.trust .owl-carousel .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.trust .owl-carousel .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.trust .owl-carousel .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.trust-signle {
  padding-left: 15px;
}

.trust-signle .container {
  min-height: 30vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-size: cover;
  color: #fff;
  position: relative;
  padding: 6rem;
}

@media (max-width: 767.98px) {
  .trust-signle .container {
    min-height: 35vw;
    padding: 2rem;
  }
}

@media (max-width: 991.98px) {
  .trust-signle .container q {
    font-size: 2.4rem;
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

.trust-signle .container .text-holder {
  position: absolute;
  top: 2rem;
  display: block;
  overflow: auto;
  bottom: 6rem;
}

@media (max-width: 991.98px) {
  .trust-signle .container .text-holder {
    position: inherit;
    bottom: 0;
  }
}

.trust-signle .container .text-holder footer {
  height: auto;
}

.trust-signle .container footer {
  background-color: rgba(0,0,0,0);
  padding: 0;
}

.trust-signle .container cite {
  font-style: normal;
}

.testimonial-new .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.testimonial-new .owl-item .item {
  flex: 1 0 auto;
  height: 100%;
}

.testimonial-new .owl-item img {
  width: auto;
  min-width: 100%;
  height: 100%;
}

@media (max-width: 767.98px) {
  .testimonial-new .owl-item img {
    width: 100%;
  }
}

.testimonial-new .row-testimonial {
  background-color: #fff;
  height: 100%;
}

.testimonial-new .col-testimonial-image {
  padding-left: unset;
  padding-right: unset;
  overflow: hidden;
}

.testimonial-new .col-testimonial-content {
  padding: 0 50px;
  padding-top: 25px;
}

@media (max-width: 991.98px) {
  .testimonial-new .col-testimonial-content {
    min-height: 300px;
    padding-bottom: 25px;
  }
}

.testimonial-new .new-testimonial {
  height: 100%;
}

.testimonial-new .item {
  background-color: #fff;
}

.testimonial-new .item .img-wrapper {
  background-size: cover;
}

.testimonial-new .item .img-wrapper img {
  display: block;
  height: 30vh;
  object-position: top;
  visibility: hidden;
}

@media (min-width: 768px) {
  .testimonial-new .item .img-wrapper img {
    height: 50vh;
  }
}

.testimonial-new .item .testimonial-text {
  color: #fff;
  padding: 2rem;
  font-style: italic;
  overflow: auto;
}

@media (min-width: 768px) {
  .testimonial-new .item .testimonial-text {
    position: absolute;
    bottom: 0px;
    background: none;
    height: auto;
    overflow: auto;
  }
}

.testimonial-new .item .testimonial-text q {
  font-size: 2.6rem;
}

.testimonial-new .item .testimonial-text p {
  font-size: 1.7rem;
}

.testimonial-new .item.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(63, 63, 63, 0.5));
  mix-blend-mode: normal;
}

@media (max-width: 767.98px) {
  .testimonial-new .item.overlay:before {
    content: none;
  }
}

.testimonial-new.colored .owl-item .item img {
  max-height: 45px;
}

.testimonial-new.colored .item {
  min-height: 300px;
  display: flex;
  flex-direction: column;
}

.testimonial-new.colored .item .testimonial-text {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-new.colored .item .testimonial-text blockquote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 60%;
}

@media (max-width: 767.98px) {
  .testimonial-new.colored .item .testimonial-text blockquote {
    max-width: 100%;
  }
}

.testimonial-new.colored .item .testimonial-text blockquote q {
  padding-bottom: 4rem;
}

@media (max-width: 767.98px) {
  .testimonial-new.colored .item .testimonial-text blockquote q {
    font-size: 2rem;
  }
}

.testimonial-new.colored .logo {
  max-width: 200px;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 1rem;
  filter: brightness(0) invert(1);
}

.testimonial-new .owl-three .fa-arrow-right,
.testimonial-new .owl-three .fa-arrow-left {
  font-size: 30px;
}

.testimonial-new .owl-three .owl-next {
  bottom: 0;
  font-size: 3rem;
  right: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-new .owl-three .owl-next:hover,
.testimonial-new .owl-three .owl-next:active {
  background: none;
}

.testimonial-new .owl-three .owl-next:focus {
  outline: 0;
}

.testimonial-new .owl-three .owl-prev {
  bottom: 0;
  font-size: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-new .owl-three .owl-prev:hover,
.testimonial-new .owl-three .owl-prev:active {
  background: none;
}

.testimonial-new .owl-three .owl-prev:focus {
  outline: 0;
}

.testimonial-new .owl-three .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .testimonial-new .owl-three .owl-dots {
    position: static;
    display: none;
  }
}

.testimonial-new .owl-three .owl-dots.disabled {
  display: block;
}

.testimonial-new .owl-three .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.testimonial-new .owl-three .owl-dots .owl-dot:focus {
  outline: 0;
}

.testimonial-new .owl-three .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .testimonial-new .owl-three .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.testimonial-new .owl-three .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.owl-four .owl-next {
  bottom: 0;
  font-size: 3rem;
  right: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.owl-four .owl-next:hover,
.owl-four .owl-next:active {
  background: none;
}

.owl-four .owl-next:focus {
  outline: 0;
}

.owl-four .owl-prev {
  bottom: 0;
  font-size: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.owl-four .owl-prev:hover,
.owl-four .owl-prev:active {
  background: none;
}

.owl-four .owl-prev:focus {
  outline: 0;
}

.owl-four .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .owl-four .owl-dots {
    position: static;
    display: none;
  }
}

.owl-four .owl-dots.disabled {
  display: block;
}

.owl-four .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.owl-four .owl-dots .owl-dot:focus {
  outline: 0;
}

.owl-four .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .owl-four .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.owl-four .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.owl-four .owl-item {
  background: #fff;
  overflow: hidden;
}

.owl-four .owl-item .new-testimonial {
  margin: 0;
  padding: 0;
}

.owl-four .owl-item .new-testimonial .col-testimonial-content {
  padding: 30px;
  padding-right: 60px;
}

@media (max-width: 991.98px) {
  .owl-four .owl-item .new-testimonial .col-testimonial-content {
    padding: 80px;
  }
}

.owl-four .owl-item .new-testimonial .col-testimonial-image {
  background-size: cover;
  background-position: center center;
}

@media (max-width: 991.98px) {
  .owl-four .owl-item .new-testimonial .col-testimonial-image {
    height: 30rem;
  }
}

.owl-four .fa-arrow-right {
  font-size: 30px;
  margin-right: 20px;
}

.owl-four .fa-arrow-left {
  font-size: 30px;
  margin-left: 20px;
}

.owl-four button .fa-arrow-right {
  font-size: 16px;
  margin-right: 0px;
}

.owl-four .owl-nav button {
  background-color: unset !important;
  color: #3f3f3f !important;
}

.owl-four .owl-nav button:hover {
  background-color: unset !important;
}

.latest:after {
  content: "";
  display: table;
  clear: both;
}

.latest h3.line {
  margin-top: 4rem;
}

.latest .row.row-eq-height .item {
  margin-bottom: 4rem;
}

.latest .row.row-eq-height .item a {
  color: #3f3f3f;
  text-decoration: none;
}

.latest .row.row-eq-height .item .card {
  border-radius: 0;
  border: none;
  height: 100%;
}

.latest .row.row-eq-height .item img {
  height: 100%;
  width: 100%;
}

.latest .row.row-eq-height .item .card-footer {
  padding: 0 0 .75rem 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

.latest .row.row-eq-height .item .card-footer .date {
  float: left;
}

.latest .row.row-eq-height .item .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .latest .row.row-eq-height .item .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

.latest .row.row-eq-height .item .badge {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
  z-index: 1;
}

.latest .row.row-eq-height .item .badge.badge-webinars {
  background-color: #14aa96;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .row.row-eq-height .item .badge.badge-seminars {
  background-color: #eb5757;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .row.row-eq-height .item .badge.badge-ontour {
  background-color: #000;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .row.row-eq-height .item .badge.badge-other {
  background-color: #949494;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .row.row-eq-height .item .badge.badge-tag {
  background-color: #3f3f3f;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  top: 49px;
}

.latest .grid-item {
  float: left;
  margin-bottom: 2rem;
  width: calc((100% - 40px)/3);
}

@media (max-width: 768px) {
  .latest .grid-item {
    width: calc((100% - 40px)/2);
  }
}

@media (max-width: 600px) {
  .latest .grid-item {
    width: calc((100% - 20px)/2);
  }
}

.latest .grid-item a {
  color: #3f3f3f;
  text-decoration: none;
}

.latest .grid-item .card {
  border-radius: 0;
  position: relative;
  border: none;
}

.latest .grid-item img {
  height: 100%;
  width: 100%;
}

.latest .grid-item .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

.latest .grid-item .card-footer .date {
  float: left;
}

.latest .grid-item .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .latest .grid-item .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

.latest .grid-item .badge {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
  z-index: 1;
}

.latest .grid-item .badge.badge-webinars {
  background-color: #14aa96;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-seminars {
  background-color: #eb5757;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-ontour {
  background-color: #000;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-other {
  background-color: #949494;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-tag {
  background-color: #3f3f3f;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  top: 49px;
}

.text-image-half strong {
  margin-bottom: 1rem;
  display: inline-block;
}

.text-image-half small {
  margin-top: .8rem;
  display: inline-block;
}

.image-gallery .image-text-overlay a {
  text-decoration: none;
  color: #3f3f3f;
}

.image-gallery .image-text-overlay a .gallery-image-text {
  display: block;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
}

.image-gallery .gallery-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
}

.image-gallery .item {
  margin-bottom: 2rem;
}

.lightbox .lb-container .lb-nav a.lb-prev {
  background: url("../img/pfeil-links.svg") 2% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-prev:hover,
.lightbox .lb-container .lb-nav a.lb-prev:focus,
.lightbox .lb-container .lb-nav a.lb-prev:active,
.lightbox .lb-container .lb-nav a.lb-prev:visited {
  background: url("../img/pfeil-links.svg") 2% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-next {
  background: url("../img/pfeil-rechts.svg") 98% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-next:hover,
.lightbox .lb-container .lb-nav a.lb-next:focus,
.lightbox .lb-container .lb-nav a.lb-next:active,
.lightbox .lb-container .lb-nav a.lb-next:visited {
  background: url("../img/pfeil-rechts.svg") 98% 48% no-repeat;
}

.lightbox .lb-container .lb-image {
  border: none;
  border-radius: 0;
}

.lightbox .lb-dataContainer .lb-data .lb-details .lb-caption {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 500;
  color: #fff;
}

.lightbox .lb-dataContainer .lb-data .lb-details .lb-number {
  color: #fff;
}

.lightbox .lb-dataContainer .lb-closeContainer a {
  background: url("../img/close-icon.svg") 100% 0 no-repeat;
  width: 25px;
  height: 25px;
}

.lightboxOverlay {
  background-color: #3f3f3f;
  opacity: .9;
}

.downloads:after {
  content: "";
  display: table;
  clear: both;
}

.downloads .box {
  background-color: #fff;
  width: 24%;
  margin: .5rem;
  float: left;
  min-height: 106px;
}

@media (min-width: 992px)and (max-width: 1199px) {
  .downloads .box {
    width: 23.9%;
  }
}

@media (max-width: 991px) {
  .downloads .box {
    min-height: 84px;
    width: 48.5%;
  }
}

@media (max-width: 991.98px) {
  .downloads .box {
    min-height: 0;
    width: 100%;
  }
}

.downloads a {
  color: #3f3f3f;
  padding: 2rem;
  text-decoration: none;
  display: inline-block;
}

.downloads span {
  float: left;
  margin-right: 1rem;
}

.downloads p {
  margin-bottom: 0;
  overflow: hidden;
}

.event-detail-intro {
  margin-top: 3rem;
}

.event-detail-intro:after {
  content: "";
  display: table;
  clear: both;
}

.event-detail-intro .topics-goals {
  float: left;
}

.event-detail-intro ul p {
  color: #3f3f3f;
  margin-bottom: 0;
}

.event-detail-intro .events {
  background-color: #3f3f3f;
  padding: 3rem;
  margin-bottom: 2rem;
  color: #fff;
}

.event-detail-intro .events .event-anchor-link {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  text-decoration: underline;
}

.event-detail-intro .events hr {
  border-bottom: 1px solid #545454;
}

.event-detail-intro .events hr:last-of-type {
  display: none;
}

.event-detail-intro .events h3:not(:first-child) {
  margin-top: 2rem;
}

.event-detail-intro .events .card {
  background-color: #3f3f3f;
  color: #fff;
  border-radius: 0;
  border: none;
  display: flex;
}

.event-detail-intro .events .card h4 {
  margin-bottom: 0;
}

.event-detail-intro .events .card .card-body {
  padding: 2rem 0;
}

.event-detail-intro .events .card .date-info {
  overflow: hidden;
}

.event-detail-intro .events .card img {
  margin-right: 1rem;
  float: left;
  position: relative;
  border-radius: 50%;
  filter: grayscale(100%);
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.event-detail-intro .events .card .text-muted {
  color: #fff !important;
}

.event-detail-intro .events .card small {
  font-size: 1.7rem;
}

.event-detail-intro .events .card a {
  float: right;
  color: #fff;
}

.share:after {
  content: "";
  display: table;
  clear: both;
}

.share .share-options {
  float: right;
}

.share strong {
  display: inline;
}

.share img {
  height: 24px;
  margin: .5rem;
}

.share img.last-of-type:before {
  content: "|";
}

.category-hero-left:after,
.category-hero-right:after {
  content: "";
  display: table;
  clear: both;
}

.category-hero-left .row,
.category-hero-right .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 991.98px) {
  .category-hero-left .row,
  .category-hero-right .row {
    margin-bottom: 0;
  }
}

.category-hero-left .row a,
.category-hero-right .row a {
  color: #3f3f3f;
  text-decoration: none;
}

.category-hero-left .row a .card,
.category-hero-right .row a .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
}

.category-hero-left .row a .card .video-head,
.category-hero-right .row a .card .video-head {
  position: relative;
}

.category-hero-left .row a .card img,
.category-hero-right .row a .card img {
  height: auto;
  width: 100%;
}

.category-hero-left .row a .card .card-footer,
.category-hero-right .row a .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.category-hero-left .row a .card .card-footer .date,
.category-hero-right .row a .card .card-footer .date {
  float: left;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: left;
  }
}

.category-hero-left .row a .card .card-footer .comments,
.category-hero-right .row a .card .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: right;
  }
}

.category-hero-left .row a .card.hero,
.category-hero-right .row a .card.hero {
  height: calc(100% - 1.2rem);
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero,
  .category-hero-right .row a .card.hero {
    height: auto;
  }
}

.category-hero-left .row a .card.hero img,
.category-hero-right .row a .card.hero img {
  height: 662px;
}

@media (max-width: 1199.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 595px;
  }
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 510px;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: auto;
  }
}

main div.content-support h1,
main div.content-support h2,
main div.content-support h3 {
  margin-top: .8em;
}

main div.content-support img {
  max-width: 100%;
}

main div.content-support .alert {
  padding: 2rem;
  margin: 2rem 0;
}

.help-content {
  min-height: calc(100vh - 20px);
  position: relative;
}

.help-content footer {
  bottom: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .help-content footer {
    position: relative;
  }
}

@media (max-width: 991.98px) {
  .help-content {
    margin-left: 0;
  }
}

nav#support-in-page-nav {
  text-align: center;
  margin: 2rem 10px 2rem 0;
  min-height: 30rem;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-y: -moz-scrollbars-none;
  -ms-overflow-style: none;
  position: relative;
  padding-bottom: 2rem;
}

@media (max-width: 991.98px) {
  nav#support-in-page-nav {
    display: none;
  }
}

nav#support-in-page-nav::-webkit-scrollbar {
  width: 0 !important;
}

nav#support-in-page-nav:before {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-image: linear-gradient(to bottom, rgba(63, 63, 63, 0.3), rgba(63, 63, 63, 0));
}

nav#support-in-page-nav a.list-group-item {
  color: #3f3f3f;
  text-decoration: none;
  text-align: right;
  background-color: rgba(0,0,0,0);
  border: 0;
  border-color: #14aa96;
  padding: .3rem 1.25rem;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

nav#support-in-page-nav a.list-group-item.active {
  border-right: 1rem solid #14aa96;
}

nav#support-in-page-nav a.list-group-item:hover {
  background-color: rgba(20,170,150,.2);
}

nav#support-in-page-nav a.list-group-item.subheading {
  font-size: .9em;
  color: rgba(63,63,63,.9);
}

nav#support-in-page-nav a.list-group-item.subheading.active {
  border-right: .5rem solid #14aa96;
}

#hilfe-content .sticky-top {
  position: -webkit-sticky;
  top: 10px;
  z-index: 50;
}

#hilfe-content _:-ms-lang(x),
#hilfe-content .sticky-top {
  max-width: 350px;
}

div.nav-helper:after {
  position: absolute;
  bottom: 0;
  display: block;
  content: "";
  background: linear-gradient(to top, #F3F3F3, transparent);
  width: 100%;
  height: 2rem;
}

.side-navigation {
  min-height: 80vh;
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .side-navigation {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    width: 80%;
    z-index: 100;
    left: -80%;
    display: none;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
  }

  .side-navigation.open {
    left: 0;
    display: unset;
  }
}

div.help-side-navigation {
  min-height: 75vh;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}

div.help-side-navigation .searchbox {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  margin: 15px;
}

div.help-side-navigation .searchbox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: #000;
  border: 0;
  font-size: 1em;
}

div.help-side-navigation .searchbox form input.form-control {
  background-color: rgba(0,0,0,0);
  padding-left: 0;
  padding-right: .75rem;
}

@media (max-width: 1199.98px) {
  div.help-side-navigation .searchbox form input.form-control {
    font-size: .75em;
  }
}

div.help-side-navigation .searchbox form input.form-control:active,
div.help-side-navigation .searchbox form input.form-control:focus {
  box-shadow: none;
}

div.help-side-navigation .searchbox form input.form-control::placeholder {
  color: rgba(0,0,0,.5);
  color: #000;
}

div.help-side-navigation .searchbox form #pressSearchSubmit:hover {
  cursor: pointer;
}

div.help-side-navigation span.parent-marker {
  background-color: #000;
  color: #fff;
  float: right;
  padding: 0px 5px 2px 6px;
  margin-top: -35px;
  margin-right: 4px;
  font-size: 1.5rem;
}

div.help-side-navigation span.parent-marker.toggle {
  padding: 0 7px 3px 7px;
}

div.help-side-navigation span.parent-marker:hover {
  cursor: pointer;
}

div.help-side-navigation .nav-ul-depth-1 {
  padding-left: 0;
  background: #fff;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 .nav-href-depth-1 {
  color: #000;
  text-decoration: none;
  font-weight: normal;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  max-width: 220px;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-2 {
  background: #dcdcdc;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 .nav-href-depth-2 {
  text-decoration: none;
  color: #000;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  max-width: 220px;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-3 {
  background: #909090;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 .nav-href-depth-3 {
  text-decoration: none;
  color: #000;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  max-width: 220px;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-4 {
  background: #666;
  display: block;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 .nav-href-depth-4 {
  text-decoration: none;
  color: #000;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  max-width: 220px;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 .active {
  background: #14aa96;
}

.sidenav {
  background-color: #545454;
  padding: 2.4rem;
  width: 350px;
  height: 100vh;
}

.sidenav a {
  color: #fff;
  text-decoration: none;
}

.sidenav a:hover {
  color: hsla(0,0%,100%,.5);
}

.sidenav a span {
  display: block;
}

.sidenav .searchbox {
  background-color: #949494;
  border: 1px solid hsla(0,0%,100%,.5);
  color: hsla(0,0%,100%,.5);
  margin-bottom: 2.4rem;
}

.sidenav .searchbox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: hsla(0,0%,100%,.5);
  border: 0;
  font-size: 1.6em;
  padding: 1rem 0 1rem 1.6rem;
}

@media (max-width: 991.98px) {
  .sidenav .searchbox form .input-group-text {
    font-size: 1.2em;
    padding: 1rem 0 1rem 1.2rem;
  }
}

@media (max-width: 991.98px) {
  .sidenav .searchbox form .input-group-prepend {
    font-size: 1.2em;
  }
}

.sidenav .searchbox form input.form-control {
  background-color: rgba(0,0,0,0);
}

.sidenav .searchbox form input.form-control:active,
.sidenav .searchbox form input.form-control:focus {
  box-shadow: none;
}

.sidenav .searchbox form input.form-control::placeholder {
  color: hsla(0,0%,100%,.5);
  color: #fff;
}

.sidenav .dd-item {
  padding: 1.6rem 0;
}

.sidenav .dd-item.visited {
  background-color: #3f3f3f;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.sidenav ul {
  font-weight: 600;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidenav ul.topics ul {
  list-style-type: none;
  font-weight: 500;
}

.sidenav ul.topics li.parent ul,
.sidenav ul.topics>li.active ul {
  display: block;
}

.sidenav ul.topics li.parent ul>li,
.sidenav ul.topics>li.active ul>li {
  padding: 1.0666666667rem 1.6rem;
}

.sidenav ul.topics li.parent ul li.active,
.sidenav ul.topics>li.active ul li.active {
  padding: 0;
}

.sidenav ul.topics li.parent ul li.active>a,
.sidenav ul.topics>li.active ul li.active>a {
  background: #e00b26;
  display: block;
  padding: 1.0666666667rem 1.6rem;
  position: relative;
}

.sidenav ul.topics li.parent ul li.active>a svg,
.sidenav ul.topics>li.active ul li.active>a svg {
  position: absolute;
  right: 1rem;
  top: 1.2rem;
}

#show-sidenav {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 74%;
  display: block;
  padding: 1em;
  background-color: #888;
  display: block;
}

@media (min-width: 768px) {
  #show-sidenav {
    display: none;
  }
}

.help-content header>nav.navbar ul.navbar-nav li a.nav-link {
  white-space: nowrap;
}

.help-content .navbar-wrapper {
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .help-content header nav.navbar.navbar-expand-lg .navbar-toggler {
    display: inline-block;
  }
}

.support-overview {
  margin-bottom: 20px;
  padding: 0 0 0 20px;
}

.support-overview .row {
  margin: 0;
}

.support-overview .row .col-md-6 {
  padding: 0 20px 20px 0;
}

.support-overview .row .col-md-6 .item {
  background-color: #fff;
  background-image: url("../img/hilfe-verlag-icon.svg");
  background-position: -2rem -6rem;
  background-repeat: no-repeat;
  background-size: 30%;
  border: none;
  border-radius: 0;
  color: #3f3f3f;
  display: block;
  height: 100%;
  text-decoration: none;
  min-height: 215px;
  padding: 2.4rem;
}

.support-overview .row .col-md-6 .item.buchhandel {
  background-image: url("../img/hilfe-buchhandel-icon.svg");
}

.support-overview .row .col-md-6 .item.empfehlungen {
  background-image: url("../img/hilfe-empfehlungen-icon.svg");
}

.support-overview .row .col-md-6 .item.datenanlieferung {
  background-image: url("../img/hilfe-datenanlieferung-icon.svg");
}

.support-overview .row .col-md-6 .item.datenbezug {
  background-image: url("../img/hilfe-datenbezug-icon.svg");
}

.support-overview .row .col-md-6 .item.zis {
  background-image: url("../img/hilfe-zis-icon.svg");
}

.support-overview .row .col-md-6 .item.neu {
  background-image: url("../img/hilfe-neu-icon.svg");
}

.support-overview .row .col-md-6 .item.colorbg {
  background-color: rgb(224.5,224.5,224.5);
  background-image: url("../img/hilfe-kontakt-icon.svg");
}

@media (max-width: 767.98px) {
  .support-overview .row .col-md-6 .item {
    height: auto;
    min-height: 0;
  }
}

.support-overview .row .col-md-6 .item h4 {
  margin-bottom: 2rem;
}

.support-overview .row .col-md-6.contact .item {
  padding: 0;
}

.support-overview .row .col-md-6.contact .item .container {
  height: 100%;
  padding: 0;
}

.support-overview .row .col-md-6.contact .item .container .row {
  height: 100%;
}

.support-overview .row .col-md-6.contact .item .container .row>div:first-of-type {
  padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px);
}

.support-overview .row .col-md-6.contact .item .container .row>div:last-of-type {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.content-support .hilfe-description-list .redactor-content {
  line-height: 18px;
  font-size: 14px;
  background-color: #fff;
  margin-bottom: -8px;
  padding: 0;
}

.content-support .hilfe-description-list .redactor-content p {
  margin: 0;
}

section.humans {
  margin-top: 3rem;
}

section.humans .row>div {
  margin-bottom: 4rem;
}

section.humans .card {
  height: 100%;
  border: none;
  border-radius: 0;
}

section.humans img {
  object-fit: cover;
}

section.humans .more h2,
section.humans .more h3,
section.humans .more h4,
section.humans .more h5 {
  font-size: 20px;
  font-size: 2rem;
}

