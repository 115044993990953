.partner

  .card
    border-radius: 0
    border: none
    display: flex
    margin-bottom: 2rem

  .card-body
    background-color: $default
    padding: 0
    .card-title
      margin-left: 3px
    .btn
      color: $white
      letter-spacing: normal
      padding: 1.2rem 1.6rem
      font-size: 1.5rem
      width: 100%
      text-align: center
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      i
        @include media-breakpoint-down(sm)
          display: inline

  h3
    margin-bottom: 1.6rem

  .img-wrapper
    align-items: center
    display: flex    
    flex-direction: row
    @include media-breakpoint-up(sm)
      height: 140px

    img
      height: auto
      width: 100%

.partner.english
  margin-top: 50px
  margin-bottom: 50px