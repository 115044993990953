main div.content-support

  h1,h2,h3
    margin-top: .8em

  img
    max-width: 100%

  .alert
    padding: $margin
    margin: $margin 0

.help-content
 
  min-height: calc(100vh - 20px)
  position: relative
  footer
    bottom: 0
    width: 100%
    @include media-breakpoint-down(md)
      position: relative
  @include media-breakpoint-down(md)
    margin-left: 0


