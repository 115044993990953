
//Styles

@font-face
  font-family: 'Graphik-Web'
  src: url(../../fonts/Graphik-Bold-Web.eot) format("embedded-opentype"), url(../../fonts/Graphik-Bold-Web.woff) format("woff"), url(../../fonts/Graphik-Bold-Web.woff2) format("woff2")
  font-weight: 700
  font-display: swap

@font-face
  font-family: 'Graphik-Web'
  src: url(../../fonts/Graphik-BoldItalic-Web.eot) format("embedded-opentype"), url(../../fonts/Graphik-BoldItalic-Web.woff) format("woff"), url(../../fonts/Graphik-BoldItalic-Web.woff2) format("woff2")
  font-weight: 700
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Graphik-Web'
  src: url(../../fonts/Graphik-RegularItalic-Web.eot) format("embedded-opentype"), url(../../fonts/Graphik-RegularItalic-Web.woff) format("woff"), url(../../fonts/Graphik-RegularItalic-Web.woff2) format("woff2")
  font-weight: 400
  font-style: italic
  font-display: swap

@font-face
  font-family: 'Graphik-Web'
  src: url(../../fonts/Graphik-Regular-Web.eot) format("embedded-opentype"), url(../../fonts/Graphik-Regular-Web.woff) format("woff"), url(../../fonts/Graphik-Regular-Web.woff2) format("woff2")
  font-weight: 400
  font-display: swap

@font-face
  font-family: 'Plantin-Web'
  src: url(../../fonts/360D0A_0_0.eot) format("embedded-opentype"), url(../../fonts/360D0A_0_0.woff) format("woff"), url(../../fonts/360D0A_0_0.woff2) format("woff2"), url(../../fonts/360D0A_0_0.ttf) format("truetype")
  font-weight: 400
  font-display: swap

html
  font-size: 62.5% // resetting 1rem = 10px

body
  font-family: 'Graphik-Web', sans-serif
  color: $default
  -webkit-font-smoothing: antialiased
  +font-size() // 1.7rem
  line-height: 1.45

h1, h2, h3, h4, h5, h6
  line-height: 1.3
  font-weight: 700
  margin-bottom: 1.2rem
  +font-size(2)

h3, h4, h5, h6
  font-weight: 600

h1, h2
  +font-size(5.2)
  line-height: 1.1667
  margin: 3rem 0 2rem
  overflow-wrap: break-word
  word-wrap: break-word
  hyphens: auto

h1.apply-h2-style
  +font-size(4)
  @media (max-width: 991px)
    +font-size(3)
    margin: 2rem 0 1rem

h1.apply-h3-style
  +font-size(2.6)
  @media (max-width: 991px)
    +font-size(2)
h2
  +font-size(4)
  @media (max-width: 991px)
    +font-size(3)
    margin: 2rem 0 1rem

h3
  +font-size(2.6)
  @media (max-width: 991px)
    +font-size(2)

  &.line
    position: relative
    z-index: 1

    &:after
      border-top: 2px solid $lightgreen
      content: ""
      margin: 0 auto /* this centers the line to the full width specified */
      position: absolute /* positioning must be absolute here, and relative positioning must be applied to the parent */
      top: 50%
      left: 0
      right: 0
      bottom: 0
      width: 100%
      z-index: -1

    span
      /* to hide the lines from behind the text, you have to set the background color the same as the container */
      background: $lightgrey
      float: none !important
      font-size: inherit !important
      padding: 0 15px 0 0 !important

h4
  +font-size(2)

h5
  +font-size(2)

h6
  +font-size(2)

q
  +font-size(3.6)
  line-height: 1.2
  font-weight: 600

cite
  +font-size(1.3)
  font-weight: 400

// Hyperlinks

a
  color: $green
  text-decoration: underline
  &:hover
    color: $lightgreen

.lead
  font-size: 1.2em
  font-weight: 400

// Custom styles
.spacey
  letter-spacing: .1em

.card
  display: inline-block
  hyphens: auto

.help-content
  h1
    @include media-breakpoint-down(sm)
      +font-size(3)
  h2
    @media (min-width: 992px)
      +font-size(3.6)
    margin-bottom: .2em
  h3
    @media (min-width: 992px)
      +font-size(2.2)
    margin: .6em 0 .4em

#content404
  background:
    color: $green
  a
    color: $white
  p
    margin: 25px auto
    img
      margin-left: -17%
      max-width: 100%
  p:first-of-type
    margin-top: 50px
  p:last-of-type
    margin-bottom: 50px
