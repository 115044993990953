$floatleft: left
$floatright: right

.rich-text
  +clearfix
  img
    max-width: 100%
    height: auto!important
    margin: 0!important
    @if ($floatleft)
      padding-right: 2rem
      padding-left: 0
    @else
      padding-left: 2rem
      padding-right: 0


.video
  margin: 4rem

