.share
  +clearfix

  .share-options
    float: right

  strong
    display: inline

  img
    height: 24px
    margin: $margin / 4
    &.last-of-type:before
      content: '|'
